// 单独对信息提示做了封装，引入message文件
import { eMsg, sMsg } from "../msg";
// 由于不想在每个代码里面判断（懒），所以将判断全部提出来，放在拦截器里面进行判断（code虽然可能一样，但是提示信息不同）
// 代码里面只写成功的返回数据
// 条件过多不考虑条件判断（后期可能会添加）
// 修改代码往往比增加代码危险很多
// 需要根据状态码的不同进行不同操作

const codeErrMsg = (code, message) => {
  // 判断errorCode[code]是否为一个函数
  // 避免因未设置的错误码而报错
  if (errorCode[code] instanceof Function) {
    // 调用该函数
    errorCode[code](message);
  }
};
const errorCode = {
  "code-1": function(message) {
    eMsg(message);
    // // // console.log("没有权限");
  },
  "code-2": function(message) {
    eMsg(message);
    // // // console.log("没有权限");
  },
  // code200: function(message) {
  //   sMsg(message);
  // },
  code201: function(message) {
    eMsg(message);
    // // // console.log('更新失败');
  },
  code202: function(message) {
    // // // console.log('参数不足');
    eMsg(message);
  },
  code203: function(message) {
    // // // console.log('输入错误');
    eMsg(message);
  },
  code205: function(message) {
    sMsg(message);
    // // // console.log('成功提示信息');
  },
  code400: function() {
    // // // console.log('token不能为空');
    // 清空本地用户ID，并重新登录
  },
  code500: function(message) {
    // 服务器错误
    eMsg(message);
  },
};
export default codeErrMsg;
