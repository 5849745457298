<template>
  <div class="productCenterDetails">
    <div class="background">
      <h2>产品中心</h2>
    </div>
    <div class="breadcrumb">
      <img
        src="../../assets/product_center_details/产品中心home_icon.png"
        alt=""
      />
      <span @click="tableSwitch(1)">首页</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span @click="tableSwitch(2)">产品中心</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span>危险化学品电子天平</span>
    </div>
    <div class="contentBox">
      <h2>危险化学品电子天平</h2>
      <img src="../../assets/product_center_details/标题装饰.png" alt="" />
      <div></div>
      <img src="../../assets/product_center_details/示例图_6.png" alt="" />
      <p class="introduce" v-html="'型号：JELON E-01'"></p>
      <p class="introduce" v-html="'特点：'"></p>
      <p
        class="introduce"
        v-html="
          '● 交直流两用，带水准仪，液晶背光显示，称量反应速度快，高稳定性。'
        "
      ></p>
      <p
        class="introduce"
        v-html="'● 传感器采用独特的锁紧装置，确保天平在运输起到保护作用。'"
      ></p>
      <p class="introduce" v-html="'功能：'"></p>
      <p
        class="introduce"
        v-html="
          '● 系统操作有：计数功能，百分比，自动校准，校准多点选择，配校准砝码（可选）。'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '● 具有单位转换（米制克拉、金盎司等）、稳定度、记忆功能、动物称重、全量程去皮等多种功能，操作更简便可靠。'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '● 内置RS232C输出接口，可直接连接危险化学品数据传输模块进行危险化学品称重数据的实时传输。'
        "
      ></p>
      <p class="introduce" v-html="'参数：'"></p>
      <p class="introduce" v-html="'● 称量范围：0-	10KG；'"></p>
      <p
        class="introduce"
        v-html="'● 可读性精度：0.001g、0.01g、0.1g、1g可选'"
      ></p>
      <p class="introduce" v-html="'● 电源：交直流两用；'"></p>
      <p
        style="margin-bottom: 60px"
        class="introduce"
        v-html="'● 秤盘尺寸：160×160mm；'"
      ></p>
    </div>
  </div>
</template>

 <script>
export default {
  data() {
    return {
      activeNavItem: 1,
    };
  },
  methods: {
    tableSwitch(v) {
      this.$bus.$emit("tableSwitch", v);
    },
  },
};
</script>

 <style lang="scss" scoped>
.productCenterDetails {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/product_center/背景.png") 0 0/100% 100%
      no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .breadcrumb {
    width: 1200px;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
    opacity: 0.8;
    display: flex;
    align-items: center;
    position: absolute;
    top: 385px;
    left: 360px;
    z-index: 9;
    img {
      width: 26px;
      height: 26px;
      margin-left: 26px;
      margin-right: 20px;
    }
    span {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      &:nth-of-type(1),
      &:nth-of-type(3) {
        cursor: pointer;
      }
      &:nth-of-type(5) {
        color: #0078ff;
      }
    }
  }
  .contentBox {
    width: 1200px;
    // height: 7746px;
    margin: 0 auto;
    text-align: center;
    h2 {
      &:nth-of-type(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 30px;
        margin-top: 115px;
      }
    }
    img {
      &:nth-of-type(1) {
        width: 220px;
        height: 3px;
        margin-top: 19px;
        margin-bottom: 40px;
      }
      &:nth-of-type(2) {
        margin-bottom: 55px;
      }
    }
    p {
      width: 100%;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
      margin-bottom: 60px;
    }
    .title {
      margin-bottom: 39px;
    }
    .example {
      margin-bottom: 40px;
    }
    .introduce {
      text-align: left;
      margin-bottom: 0px;
    }
  }
  .navMenu {
    width: 120px;
    height: 900px;
    background: #f1f8ff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.13);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 459px;
    left: 102px;
    .navItem {
      width: 120px;
      height: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .decorate {
        width: 78px;
        height: 1px;
        background: #dcdcdc;
        position: absolute;
        top: -0.5px;
        left: 21px;
      }
      img {
        position: relative;
        top: -15px;
        &:nth-of-type(1) {
          display: block;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
      span {
        width: 100%;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 65px;
        left: 0;
      }
    }
    .activeNavItem {
      background: #0078ff;
      .decorate {
        display: none;
      }
      img {
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
      span {
        color: #ffffff;
      }
    }
  }
}
</style>