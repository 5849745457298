<template>
  <div class="bottom">
    <div class="btm_1">
      <span>Contact Us</span>
      <div @click="tableSwitch(6)">联系我们</div>
    </div>
    <div class="btm_2">
      <img src="../../../assets/home/logo_底部.png" alt="logo_底部" />
      <div>
        <span @click="tableSwitch(1)">首页 </span>
        <span v-html="'\u00a0'"></span>
        <span>|</span>
        <span v-html="'\u00a0'"></span>
        <span @click="tableSwitch(2)"> 产品中心 </span>
        <span v-html="'\u00a0'"></span>
        <span>|</span>
        <span v-html="'\u00a0'"></span>
        <span @click="tableSwitch(3)"> 解决方案 </span>
        <span v-html="'\u00a0'"></span>
        <span>|</span>
        <span v-html="'\u00a0'"></span>
        <span @click="tableSwitch(4)"> 服务支持 </span>
        <span v-html="'\u00a0'"></span>
        <span>|</span>
        <span v-html="'\u00a0'"></span>
        <span @click="tableSwitch(5)"> 客户案例 </span>
        <span v-html="'\u00a0'"></span>
        <span>|</span>
        <span v-html="'\u00a0'"></span>
        <span @click="tableSwitch(6)"> 关于我们 </span>
      </div>
      <div>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          style="color: #999; text-decoration: none"
        >
          Copyright © 2021河南捷隆科技有限公司豫ICP备2021005794号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  activated() {},
  data() {
    return {};
  },
  methods: {
    tableSwitch(v) {
      this.$bus.$emit("tableSwitch", v);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  height: 196px;
  .btm_1 {
    height: 70px;
    background: linear-gradient(100deg, #38424c 0%, #1f252c 100%);
    position: relative;
    span {
      height: 19px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 19px;
      position: absolute;
      top: 27px;
      left: 361px;
    }
    div {
      width: 150px;
      height: 40px;
      border: 1px solid #ffffff;
      border-radius: 20px;
      text-align: center;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 362px;
    }
  }
  .btm_2 {
    height: 126px;
    position: relative;
    img {
      width: 169px;
      height: 26px;
      position: absolute;
      top: 50px;
      left: 362px;
    }
    div:nth-of-type(1) {
      height: 16px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      position: absolute;
      top: 44px;
      right: 360px;
      span {
        cursor: pointer;
      }
    }
    div:nth-of-type(2) {
      height: 16px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
      position: absolute;
      top: 73px;
      right: 360px;
    }
  }
}
</style>