import { Message } from "element-ui";
export const sMsg = (message) => {
  Message.success({ message });
};
export const wMsg = (message) => {
  Message.warning({ message });
};
export const eMsg = (message) => {
  Message.error({ message });
};
export const iMsg = (message) => {
  Message.info({ message });
};
