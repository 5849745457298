<template>
  <div class="aboutUs">
    <div class="background">
      <h2>关于我们</h2>
    </div>
    <div class="content">
      <div class="paragraph_1">
        <h2>ABOUT US</h2>
        <h3>河南捷隆科技有限公司</h3>
        <p>
          河南捷隆科技有限公司位于郑州市中原区，成立于2016年，是一家集研发、销售和技术服务为一体的高科技企业。捷隆科技致力于先进的射频识别技术（RFID）应用、软硬件开发、系统集成，自主研发成功实验室安全智能监控管理系统、智能危险化学品管理系统、智能危险化学品柜、实验室安全知识学习及考核系统、实验室化学挥发气体净化系统等，并完全拥有自主知识产权。公司产品广泛应用于高等院校、科研院所、企事业单位，并可以根据用户实际需求量身定制个性化产品。公司拥有一批学历高、经验丰富的技术团队，同时与高校和科研院所密切高效合作，针对市场需求，持续研发高科技产品。
        </p>
        <img src="../../assets/about_us/正文装饰_1.png" alt="" />
      </div>
      <div class="paragraph_3">
        <h2>contact us</h2>
        <h3>联系我们</h3>
        <!-- <img class="map" src="../../assets/about_us/公司位置.png" alt="" /> -->
        <div ref="map" class="map"></div>
        <p
          v-html="
            '市场营销部: 13693717018 / 0371-86231817 <br/> 企业邮箱: henanjielong@163.com <br/> 公司地址:郑州市中原区建设路198号西元国际广场东塔写字楼B座809室'
          "
        ></p>
        <div class="qq" @click="goQQ()">
          <img src="../../assets/about_us/qq.png" alt="" />
          <span>QQ联系</span>
        </div>
        <div class="wx" @click="goWx()">
          <img src="../../assets/about_us/wx.png" alt="" />
          <span>微信联系</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.establishMap();
  },
  data() {
    return {
      map: null,
      point: null,
    };
  },
  methods: {
    establishMap() {
      let dom = this.$refs.map;
      var map = this.map;
      var point = this.point;
      map = new BMap.Map(dom);
      point = new BMap.Point(113.608505, 34.763645);
      var point_2 = new BMap.Point(113.608165, 34.763975);
      map.centerAndZoom(point, 19);
      map.enableScrollWheelZoom(true);
      var marker = new BMap.Marker(point); // 创建标注
      var label = new BMap.Label("河南捷隆科技有限公司"); // 创建标注
      label.setContent(
        "<div style='width: 150px;height: 50px;display: flex;justify-content: center;align-items: center;position: relative;box-shadow: 1px 2px 1px rgb(0 0 0 / 15%);border-radius: 5px; top:-65px;left:-65.5px;background-color: #fff;'><span>河南捷隆科技有限公司</span> <img style='position: absolute;bottom: -10px;left: calc((100% - 16px) / 2);'' src='https://webmap0.bdimg.com/image/api/tail_shadow.png' alt=''></div>"
      );
      label.setStyle({
        // width: "150px",
        // height: "50px",
        border: "none",
        backgroundColor: "none",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // color: "#4c4c4c",
        // boxShadow: "1px 2px 1px rgb(0 0 0 / 15%)",
        // borderRadius: "5px",
      });
      // label.setPosition(point_2);
      marker.setTitle("河南捷隆科技有限公司");
      marker.setLabel(label);
      // map.addOverlay(label);
      map.addOverlay(marker);
      // http://api.map.baidu.com/marker?coord_type=bd09ll&location=34.763645,113.608505&title=捷龙科技&content=郑州市中原区建设路198号西元国际广场东塔写字楼B座809室&output=html&src=webapp.JeLonTech.openMap
    },
    goQQ() {
      this.$bus.$emit("qqContact");
    },
    goWx() {
      this.$bus.$emit("wxContact");
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/about_us/背景.png") 0 0/100% 100% no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    position: relative;

    // display: flex;
    .paragraph_1 {
      height: 390px;
      margin-bottom: 40px;
      h2 {
        font-size: 60px;
        font-family: FZZongYi-M05S;
        font-weight: 400;
        font-style: italic;
        color: #38424c;
        line-height: 45px;
        opacity: 0.1;
        position: absolute;
        top: 50px;
        left: 0;
      }
      h3 {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #0078ff;
        line-height: 35px;
        position: absolute;
        top: 80px;
        left: 0;
      }
      p {
        width: 643px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
        position: absolute;
        top: 160px;
        left: 0;
      }
      img {
        position: absolute;
        top: 40px;
        right: 0;
      }
    }
    .paragraph_2 {
      .certificateList {
        width: 100%;
        height: 310px;
        display: flex;
        justify-content: space-between;
        img {
          width: 213px;
          height: 310px;
        }
      }
    }
    .paragraph_3 {
      width: 100%;
      height: 824px;
      position: relative;
      > h2 {
        width: 100%;
        font-size: 60px;
        font-family: FZZongYi-M05S;
        font-weight: 400;
        font-style: italic;
        color: #38424c;
        line-height: 45px;
        opacity: 0.1;
        text-align: center;
        position: absolute;
        top: 72px;
        left: 0;
      }
      > h3 {
        width: 100%;
        font-size: 24px;
        font-family: FZZongYi-M05S;
        font-weight: 400;
        font-style: italic;
        color: #010101;
        line-height: 23px;
        text-align: center;
        position: absolute;
        top: 110px;
        left: 0;
      }
      > .map {
        width: 100%;
        height: 379px;
        position: absolute;
        top: 170px;
        left: 0;
      }
      p {
        height: 76px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
        position: absolute;
        top: 560px;
        left: 0;
      }
      @mixin c() {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 16px;
        }
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
        }
      }
      .qq {
        width: 150px;
        height: 40px;
        background: #0078ff;
        border-radius: 20px;
        position: absolute;
        top: 681px;
        left: 0;
        @include c();
      }
      .wx {
        width: 150px;
        height: 40px;
        background: #6ac160;
        border-radius: 20px;
        position: absolute;
        top: 681px;
        left: 168px;
        @include c();
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.m_b {
  margin-bottom: 25px;
}
.top {
  ::v-deep .mapImg {
    position: absolute;
    bottom: 10px;
    left: calc((100% - 16px) / 2);
  }
}
</style>
