<template>
  <div class="home">
    <Top />
    <router-view />
    <!-- <Index /> -->
    <Bottom />
  </div>
</template>

<script>
// @ is an alias to /src
import Top from "../components/home/components/top";
import Bottom from "../components/home/components/bottom";
// import Index from "../components/home/index";

export default {
  name: "Home",
  components: { Top, Bottom },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  min-width: 1900px;
  margin: 0 auto;
}
</style>
