<template>
  <div class="solution">
    <div class="background">
      <h2>解决方案</h2>
    </div>
    <div class="switchBtn">
      <div
        @click="activeSwitchBtn = 1"
        :class="{ activeSwitchBtn: activeSwitchBtn == 1 }"
      >
        <span>全智能危化品管理方案</span>
        <i></i>
      </div>
      <div
        @click="activeSwitchBtn = 2"
        :class="{ activeSwitchBtn: activeSwitchBtn == 2 }"
      >
        <span>普通危化品柜的智能管理方案</span>
        <i></i>
      </div>
      <div
        @click="activeSwitchBtn = 3"
        :class="{ activeSwitchBtn: activeSwitchBtn == 3 }"
      >
        <span>危化品手持机智能管理方案</span>
        <i></i>
      </div>
      <div
        @click="activeSwitchBtn = 4"
        :class="{ activeSwitchBtn: activeSwitchBtn == 4 }"
      >
        <span>全智能后台管理方案</span>
        <i></i>
      </div>
    </div>
    <div class="contentBox">
      <div v-if="activeSwitchBtn == 1" class="tab">
        <img class="icon" src="../../assets/solution/icon_1.png" alt="" />
        <h2>全智能危化品管理方案</h2>
        <h3>解决方案内容</h3>
        <h4>Solution content</h4>
        <div style="height: 300px"></div>
        <div class="content">
          <el-row>
            <el-col class="flex fj-c">
              <el-image
                class="titleImg"
                :src="require('../../assets/solution/1.jpg')"
                :preview-src-list="imgArr1"
              ></el-image>
            </el-col>
            <el-col>
              <p>
                全智能危险化学品管理系统包括智能控制柜、智能储存柜及后台管理系统。
              </p>
              <p>
                智能控制柜集成了双人身份认证（人脸+刷卡+指纹）、智能RFID识别技术、自动称重系统，自动进行双人身份认证、自动识别试剂及自动称重上传试剂使用重量；自动查询柜内试剂及库存量，自动语音报警各类违规操作。
              </p>
              <p>
                智能储存柜柜体采用双层冷轧钢板，符合防火防爆规范要求；防静电接地装置，标准的泄压口；优质稳固的福马轮，便于移动及稳定放置；配置电控双锁符合管控规范；配备停电应急电源（UPS电源）、应急钥匙开锁、应急电源接口；根据需要可选配两门、四门、六门柜体；智能储存柜柜内配置一次成型PP材质内胆及层板，可以根据需要调节托盘层板之间距离，满足防腐及承重要求；集成安卓工控触摸一体机，实时监测显示柜内温湿度、TVOC浓度，门锁及风机状态，各类违规操作实时语音文字报警及报警状态显示上报；安装智能安防网络摄像头，实时监测存储每次打开柜门的影像资料，可本地及远程查看及下载；根据柜内TVOC浓度变化，自动控制开启及关闭风机，可通过外接管道将挥发性有害气体排出室外或配备过滤模块自动过滤净化有害气体；RFID试剂智能识别、智能实时盘点。
              </p>
              <p>
                手机端、电脑端双系统管理，可以实现远程柜门控制，可以移动端查看库存、使用情况及违规操作报警。
              </p>
              <p>
                强大的前端、后台管理功能，自动生成库存台账、使用台账及各类报表，方便数据下载导出。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="activeSwitchBtn == 2" class="tab">
        <img class="icon" src="../../assets/solution/icon_1.png" alt="" />
        <h2>普通危化品柜的智能管理方案</h2>
        <h3>解决方案内容</h3>
        <h4>Solution content</h4>
        <div style="height: 300px"></div>
        <div class="content">
          <el-row>
            <el-col class="flex fj-c">
              <el-image
                class="titleImg"
                :src="require('../../assets/solution/2.jpg')"
                :preview-src-list="imgArr2"
              ></el-image>
            </el-col>
            <el-col>
              <el-image
                class="titleImg"
                :src="require('../../assets/solution/2-1.png')"
                :preview-src-list="imgArr2"
              ></el-image>
            </el-col>
            <el-col class="flex jc-sb">
              <el-image
                style="width: 30%"
                class="titleImg"
                :src="require('../../assets/solution/2-2.png')"
                :preview-src-list="imgArr2"
              ></el-image>
              <el-image
                style="width: 30%"
                class="titleImg"
                :src="require('../../assets/solution/2-3.png')"
                :preview-src-list="imgArr2"
              ></el-image>
              <el-image
                style="width: 30%"
                class="titleImg"
                :src="require('../../assets/solution/2-4.png')"
                :preview-src-list="imgArr2"
              ></el-image>
            </el-col>
            <el-col>
              <p>
                目前各个科研院所、企事业单位、医院等单位的实验室，为了响应国家危险化学品管理的要求，配备了大量的普通危险化学品储存柜；这些普通危化品安全柜的特点已经不能满足目前国家有关危险化学品管控需要，不能实现自动盘点、不能自动生成使用台账和库存台账，不能自动记录危险化学品的使用流转详情，不能实现智能身份认证管理，所有的数据记录都是手工进行，容易漏记和错误记录。
              </p>
              <p>
                为了使没有条件更换智能危险化学品柜的单位和实验室，在现有条件下，提供了智能化改造方案，智能化管控方案如下：
              </p>
              <p>
                硬件配置：智能控制柜、手持盘存机、RFID电子标签、实验室普通危险化学品柜
                系统工作流程：
              </p>
              <p>
                试剂添加入库：采购到的试剂，在每个试剂瓶粘贴一个RFID电子标签（唯一的身份），在系统后台将该瓶试剂录入，并打开柜门放入柜内，完成入库操作。
              </p>
              <p>
                双人刷卡或者人脸登录智能控制柜系统，进入功能模块：查询、借出、归还、注销。
              </p>
              <p>自动扫描盘点库存（使用手持盘存机）。</p>
              <p>
                管理系统后台功能：显示试剂库存及操作记录，环境温湿度及TVOC浓度，并根据TVOC浓度控制排风风机开启关闭，违规报警、库存及使用台账功能，盘存记录功能。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="activeSwitchBtn == 3" class="tab">
        <img class="icon" src="../../assets/solution/icon_1.png" alt="" />
        <h2>危化品手持机智能管理方案</h2>
        <h3>解决方案内容</h3>
        <h4>Solution content</h4>
        <div style="height: 300px"></div>
        <div class="content">
          <el-row>
            <el-col class="flex fj-c">
              <el-image
                class="titleImg"
                :src="require('../../assets/solution/3.jpg')"
                :preview-src-list="imgArr3"
              ></el-image>
            </el-col>
            <el-col class="flex jc-sb">
              <el-image
                style="width: 19%"
                class="titleImg"
                :src="require('../../assets/solution/3-1.png')"
                :preview-src-list="imgArr3"
              ></el-image>
              <el-image
                style="width: 19%"
                class="titleImg"
                :src="require('../../assets/solution/3-2.png')"
                :preview-src-list="imgArr3"
              ></el-image>
              <el-image
                style="width: 19%"
                class="titleImg"
                :src="require('../../assets/solution/3-3.png')"
                :preview-src-list="imgArr3"
              ></el-image>
              <el-image
                style="width: 19%"
                class="titleImg"
                :src="require('../../assets/solution/3-4.png')"
                :preview-src-list="imgArr3"
              ></el-image>
              <el-image
                style="width: 19%"
                class="titleImg"
                :src="require('../../assets/solution/3-5.png')"
                :preview-src-list="imgArr3"
              ></el-image>
            </el-col>
            <el-col>
              <p>
                为了使实验室危险化学品及化学试剂管理更加规范化、智能化，在预算有限的情况下，我们最新研发出手持机版本的普通试剂储存柜智能化管理方案。该方案大大的降低了用户的管理成本，适用范围更加广泛，对RFID电子标签和一二维码标签的用户场景都能做到有效的管控。
              </p>
              <p>
                该方案由手持机（RFID射频和一二维码扫描复合型机器）、数据传输模块、电子天平、标签组成。该方案有RFID电子标签管理模式、一二维码标签管理模式及RFID电子标签和一二维码标签的复合管理模式，可以根据用户场景进行切换。
              </p>
              <p>系统方案：</p>
              <p>
                试剂添加入库：采购到的试剂，每个试剂瓶粘贴一个RFID电子标签（或者一二维码标签），在系统后台将该瓶试剂录入。
              </p>
              <p>
                账号密码或者人脸识别登录系统，进入功能模块：查询、领用、归还、注销、入库。
              </p>
              <p>
                领用：查询需要领用的试剂添加到领用列表，然后从试剂柜中拿出要使用的试剂，用手持机扫描标签，然后确认借出。
              </p>
              <p>
                归还：将需要归还的试剂放在系统电子天平上，并用手持机扫描标签，完成归还操作。
              </p>
              <p>注销：在借出列表中对需要注销的试剂，申请注销。</p>
              <p>
                盘存：对柜内试剂进行盘存操作，并实时与后台进行比对，并将盘存结果进行存储。
              </p>
              <p>
                管理系统后台功能：显示试剂库存及操作记录，违规报警、库存及使用台账功能。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="activeSwitchBtn == 4" class="tab">
        <img class="icon" src="../../assets/solution/icon_1.png" alt="" />
        <h2>全智能后台管理方案</h2>
        <h3>解决方案内容</h3>
        <h4>Solution content</h4>
        <div style="height: 300px"></div>
        <div class="content">
          <el-row>
            <el-col class="flex fj-c">
              <el-image
                class="titleImg"
                :src="require('../../assets/solution/4.png')"
                :preview-src-list="imgArr4"
              ></el-image>
            </el-col>
            <el-col class="flex jc-sb">
              <el-image
                style="width: 20%"
                class="titleImg"
                :src="require('../../assets/solution/4-1.png')"
                :preview-src-list="imgArr4"
              ></el-image>
              <el-image
                style="width: 20%"
                class="titleImg"
                :src="require('../../assets/solution/4-2.png')"
                :preview-src-list="imgArr4"
              ></el-image>
              <el-image
                style="width: 20%"
                class="titleImg"
                :src="require('../../assets/solution/4-3.png')"
                :preview-src-list="imgArr4"
              ></el-image>
              <el-image
                style="width: 20%"
                class="titleImg"
                :src="require('../../assets/solution/4-4.png')"
                :preview-src-list="imgArr4"
              ></el-image>
              <el-image
                style="width: 20%"
                class="titleImg"
                :src="require('../../assets/solution/4-5.png')"
                :preview-src-list="imgArr4"
              ></el-image>
            </el-col>
            <el-col>
              <p>1、登录：根据各级用户权限进行认证登录</p>
              <p>2、页面展示</p>
              <p>
                （1）管理主页：目系统所有储存柜列表、智能柜操作记录轮播滚动条、一周试剂使用排行榜、操作记录（登录、借出、归还、报警）列表、各种分类试剂分布图示等。
              </p>
              <p>
                （2）智能柜主页：本柜今日操作数（借出、归还、报警、待归还、注销、总操作数）图示、温湿度曲线及当前数据、TVOC浓度曲线及当前数据，柜子物理状态（网络信号、排风机、门锁）实时展示，功能操作模块（人员、查找、在库试剂、试剂信息、试剂注销审批、违规报警、报表及实时视频监控画面及开门录像资料下载），智能柜所属单位、所在位置、管理员及联系方式等
              </p>
              <p>
                （3）在库试剂页面：在库试剂总瓶数，液体、固体试剂瓶数，非法试剂瓶数，每种试剂的名称、CAS号、RFID号码、试剂状态、规格、净含量、形态、入库时间、过去时间
                报表台账页面。
              </p>
              <p>
                （4）库存台账：展示品名、CAS号、期初数（从智能柜开始使用开始保存）、入库量、出库量、期末数，并可设置试剂类型查询、试剂名称（CAS号)查询、任意时间设置查询，可将查询结果导出。
              </p>
              <p>
                （5）使用台账：展示品名、CAS号、RFID号、智能柜编号、操作人、操作类型、使用量、净含量、操作时间，并可设置试剂类型查询、操作人试剂名称（CAS号)查询、任意时间设置查询，可将查询结果导出。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="switchBtnL" @click="prev()">
        <img
          v-show="activeSwitchBtn != 1"
          src="../../assets/solution/切换按钮_左_1.png"
          alt=""
        />
        <img
          v-show="activeSwitchBtn == 1"
          src="../../assets/solution/切换按钮_左_2.png"
          alt=""
        />
      </div>
      <div class="switchBtnR" @click="next()">
        <img
          v-show="activeSwitchBtn != 4"
          src="../../assets/solution/切换按钮_右_1.png"
          alt=""
        />
        <img
          v-show="activeSwitchBtn == 4"
          src="../../assets/solution/切换按钮_右_2.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSwitchBtn: 1,
      imgArr1: [require("../../assets/solution/1.jpg")],
      imgArr2: [
        require("../../assets/solution/2.jpg"),
        require("../../assets/solution/2-1.png"),
        require("../../assets/solution/2-2.png"),
        require("../../assets/solution/2-3.png"),
        require("../../assets/solution/2-4.png"),
      ],
      imgArr3: [
        require("../../assets/solution/3.jpg"),
        require("../../assets/solution/3-1.png"),
        require("../../assets/solution/3-2.png"),
        require("../../assets/solution/3-3.png"),
        require("../../assets/solution/3-4.png"),
        require("../../assets/solution/3-5.png"),
      ],
      imgArr4: [
        require("../../assets/solution/4.png"),
        require("../../assets/solution/4-1.png"),
        require("../../assets/solution/4-2.png"),
        require("../../assets/solution/4-3.png"),
        require("../../assets/solution/4-4.png"),
        require("../../assets/solution/4-5.png"),
      ],
    };
  },
  methods: {
    prev() {
      if (this.activeSwitchBtn > 1) {
        this.activeSwitchBtn--;
      }
    },
    next() {
      if (this.activeSwitchBtn < 4) {
        this.activeSwitchBtn++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.solution {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/solution/背景.png") 0 0/100% 100% no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .switchBtn {
    width: 1200px;
    height: 124px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
    opacity: 1;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 385px;
    left: 360px;
    z-index: 9;
    div {
      width: 50%;
      height: 62px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      span {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bolder;
        color: #333333;
      }
      i {
        display: none;
        width: 100%;
        height: 2px;
        background: #0078ff;
        box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
        opacity: 0.8;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .activeSwitchBtn {
      span {
        color: #0078ff;
      }
      i {
        display: block;
      }
    }
  }
  .contentBox {
    width: 1200px;
    // height: 890px;
    margin: 0 auto;
    position: relative;
    .tab {
      .icon {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 155px;
        left: 0;
      }
      h2 {
        height: 36px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 36px;
        position: relative;
        top: 152px;
        left: 46px;
      }
      h3 {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        position: absolute;
        top: 257px;
        left: 0;
      }
      h4 {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        position: absolute;
        top: 288px;
        left: 0;
      }
      .content {
        width: 75%;
        margin: 0 auto;
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          text-indent: 32px;
          color: #666666;
          line-height: 30px;
        }
        & ::v-deep .titleImg {
          width: 100%;
          margin: 0 auto;
          margin-bottom: 15px;
        }
        & ::v-deep .titleImgItem {
          width: 30%;
          margin: 0 auto;
          margin-bottom: 15px;
        }
      }
      .decorate {
        width: 10px;
        height: 531px;
        position: absolute;
        top: 227px;
        right: 0;
        z-index: 1;
      }
    }
    @mixin com() {
      width: 11px;
      height: 20px;
      position: absolute;
      top: 168px;
      img {
        &:nth-of-type(1) {
          cursor: pointer;
        }
        &:nth-of-type(2) {
          cursor: not-allowed;
        }
      }
    }
    .switchBtnL {
      @include com();
      right: 54px;
    }
    .switchBtnR {
      @include com();
      right: 0;
    }
  }
}
</style>