var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productCenterDetails"},[_vm._m(0),_c('div',{staticClass:"breadcrumb"},[_c('img',{attrs:{"src":require("../../assets/product_center_details/产品中心home_icon.png"),"alt":""}}),_c('span',{on:{"click":function($event){return _vm.tableSwitch(1)}}},[_vm._v("首页")]),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp;>&nbsp;')}}),_c('span',{on:{"click":function($event){return _vm.tableSwitch(2)}}},[_vm._v("产品中心")]),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp;>&nbsp;')}}),_c('span',[_vm._v("危险化学品电子天平")])]),_c('div',{staticClass:"contentBox"},[_c('h2',[_vm._v("危险化学品电子天平")]),_c('img',{attrs:{"src":require("../../assets/product_center_details/标题装饰.png"),"alt":""}}),_c('div'),_c('img',{attrs:{"src":require("../../assets/product_center_details/示例图_6.png"),"alt":""}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('型号：JELON E-01')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('特点：')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '● 交直流两用，带水准仪，液晶背光显示，称量反应速度快，高稳定性。'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('● 传感器采用独特的锁紧装置，确保天平在运输起到保护作用。')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('功能：')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '● 系统操作有：计数功能，百分比，自动校准，校准多点选择，配校准砝码（可选）。'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '● 具有单位转换（米制克拉、金盎司等）、稳定度、记忆功能、动物称重、全量程去皮等多种功能，操作更简便可靠。'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '● 内置RS232C输出接口，可直接连接危险化学品数据传输模块进行危险化学品称重数据的实时传输。'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('参数：')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('● 称量范围：0-	10KG；')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('● 可读性精度：0.001g、0.01g、0.1g、1g可选')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('● 电源：交直流两用；')}}),_c('p',{staticClass:"introduce",staticStyle:{"margin-bottom":"60px"},domProps:{"innerHTML":_vm._s('● 秤盘尺寸：160×160mm；')}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('h2',[_vm._v("产品中心")])])}]

export { render, staticRenderFns }