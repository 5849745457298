import {
  postNotoken,
  postRequest,
  postBodyRequest,
  filesRequest,
} from "./httpRequest";

export const api = {
  // 登录 /login/user-login
  login: (params) => postNotoken("/login/web-login", params),
};
