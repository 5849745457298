let getB64 = function(imageFile, callback, errorCallback) {
  try {
    let reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = function(e) {
      if (callback) {
        let base64Str = e.target.result;
        callback(base64Str);
      }
    };
  } catch (error) {
    console.error(error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
};

export default getB64;
