import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import bus from "../Tools/bus"; // 事件总线

Vue.use(VueRouter);

import Index from "../components/home/1_index";
import ProductCenter from "../components/home/2_product_center";
import productCenterDetails_1 from "../components/home/2_product_center_details_1";
import productCenterDetails_2 from "../components/home/2_product_center_details_2";
import productCenterDetails_3 from "../components/home/2_product_center_details_3";
import productCenterDetails_4 from "../components/home/2_product_center_details_4";
import productCenterDetails_5 from "../components/home/2_product_center_details_5";
import productCenterDetails_6 from "../components/home/2_product_center_details_6";
import solution from "../components/home/3_solution";
import erviceSupport from "../components/home/4_service_support";
import customerCase from "../components/home/5_customer_case";
import aboutUs from "../components/home/6_about_us";

const routes = [
  {
    path: "/",
    // name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Index",
        component: Index,
      },
      {
        path: "/ProductCenter",
        name: "ProductCenter",
        component: ProductCenter,
      },
      {
        path: "/productCenterDetails_1",
        name: "productCenterDetails_1",
        component: productCenterDetails_1,
      },
      {
        path: "/productCenterDetails_2",
        name: "productCenterDetails_2",
        component: productCenterDetails_2,
      },
      {
        path: "/productCenterDetails_3",
        name: "productCenterDetails_3",
        component: productCenterDetails_3,
      },
      {
        path: "/productCenterDetails_4",
        name: "productCenterDetails_4",
        component: productCenterDetails_4,
      },
      {
        path: "/productCenterDetails_5",
        name: "productCenterDetails_5",
        component: productCenterDetails_5,
      },
      {
        path: "/productCenterDetails_6",
        name: "productCenterDetails_6",
        component: productCenterDetails_6,
      },
      {
        path: "/solution",
        name: "solution",
        component: solution,
      },
      {
        path: "/erviceSupport",
        name: "erviceSupport",
        component: erviceSupport,
      },
      {
        path: "/customerCase",
        name: "customerCase",
        component: customerCase,
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: aboutUs,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  next();
  bus.$emit("resetRouter");
});
export default router;
