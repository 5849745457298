import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 手动添加的插件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueCookies from "vue-cookies"; // cookie操作
import axios from "axios";

// 安装插件
Vue.use(ElementUI);
Vue.use(VueCookies);

// 引入自定义工具
import bus from "./Tools/bus"; // 事件总线
import getB64 from "./Tools/getB64"; // 图片转B64数据 (imageFile, callback, errorCallback)
import getTime from "./Tools/getTime"; // 时间戳转换 (v)
import { sMsg, wMsg, eMsg, iMsg } from "./Tools/msg"; // 警告信息 s成功 w警告 e错误 (msgtext)
import {
  setSS,
  getSS,
  setLS,
  getLS,
  setCK,
  getCK,
  delCK,
  isCK,
} from "./Tools/storage"; // 本地储存LS与服务器储存SS
import { api } from "./Tools/request"; // 接口api
// import getMaxH from "./Tools/getMaxH"; // 获取浏览器高度
import { OL, CL } from "./Tools/loading"; // 开启/关闭加载图
import { loginWS, loginWSC } from "./Tools/loginWebsocket"; // 创建Websocket服务

// 杂项
// getMaxH(); // 获取当前窗口的高度 储存在VueX中

// 声明工具变量
// var websocket = null; //判断当前浏览器是否支持WebSocket
var ip = "http://8.140.137.245:9099/";

// 注册工具
Vue.prototype.$ip = ip;
Vue.prototype.$axios = axios; // axios本体
Vue.prototype.$bus = bus;
Vue.prototype.$api = api; // 接口列表
Vue.prototype.$getB64 = getB64; // 图片转换b64数据
Vue.prototype.$getTime = getTime; // 转换时间戳
Vue.prototype.$sMsg = sMsg; // 成功信息
Vue.prototype.$wMsg = wMsg; // 警告信息
Vue.prototype.$eMsg = eMsg; // 错误信息
Vue.prototype.$iMsg = iMsg; // 提示信息
Vue.prototype.$setSS = setSS; // 存储服务器缓存
Vue.prototype.$getSS = getSS; // 获取服务器缓存
Vue.prototype.$setLS = setLS; // 存储本地缓存
Vue.prototype.$getLS = getLS; // 获取本地缓存
Vue.prototype.$setCK = setCK; // 写入cookies
Vue.prototype.$getCK = getCK; // 获取cookies
Vue.prototype.$delCK = delCK; // 删除cookies
Vue.prototype.$isCK = isCK; // 判断cookies是否存在
Vue.prototype.$OL = OL; // 开启loading效果
Vue.prototype.$CL = CL; // 关闭loading效果
Vue.prototype.$loginWS = loginWS; // 开启WS
Vue.prototype.$loginWSC = loginWSC; // 关闭WS

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
