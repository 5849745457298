import axios from "axios";
import codeErrMsg from "./codeErrMsg";
import { getCK } from "../storage";
import { eMsg } from "../msg";
import router from "../../router/index";

axios.interceptors.response.use(
  function(res) {
    // 获取数据后的拦截器
    // 对响应数据做点什么
    // 这里的状态码可根据实际情况来修改
    if (res.data.code == "200") {
      // // // console.log("请求成功");
      codeErrMsg(`code${res.data.code}`, res.data.msg);
      return Promise.resolve(res);
    } else {
      // // // console.log("请求出错");
      // codeErrMsg(`code${res.data.code}`, res.data.msg);
      eMsg(res.data.message);
      if (
        res.data.message == "异地登录，请重新登陆！" &&
        router.app.$route.path != "/"
      ) {
        router.push("/");
      }
      return Promise.reject(res);
    }
  },
  function(error) {
    // 对响应错误做点什么
    codeErrMsg(`code${500}`, "未知错误");
    return Promise.reject(error);
  }
);

// 用于登录的无token的post请求
export const postNotoken = (url, params) => {
  return axios.post(
    "/api" + url,
    {},
    {
      params,
    }
  );
};

// 用于一般场景下的post请求（可编辑接口，非other）
export const postRequest = (url, params) => {
  return axios.post(
    "/api" + url,
    {},
    {
      params,
      headers: {
        token: getCK("token"),
      },
    }
  );
};

// 用于更新简介的post请求
export const postBodyRequest = (url, params) => {
  return axios.post("/api" + url, params, {
    headers: {
      token: getCK("token"),
    },
  });
};

// 用于上传文件场景的post上传
export const filesRequest = (
  url,
  params
  // headers = { "Content-Type": "multipart/form-data" }
) => {
  return axios.post("/api" + url, params, {
    headers: {
      token: getCK("token"),
      "Content-Type": "multipart/form-data",
    },
  });

  // axios({
  //   method: "post",
  //   url: "/api" + url,
  //   data: params,
  //   headers,
  // });
};
