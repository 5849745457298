<template>
  <div class="productCenter">
    <div class="background">
      <h2>产品中心</h2>
    </div>
    <div class="switchBtn">
      <div
        :class="{ activeBtn: activeBtn == 1 }"
        @click="activeBtn = 1"
        class="btnL"
      >
        <span>安全产品</span>
        <i></i>
      </div>
      <div
        :class="{ activeBtn: activeBtn == 2 }"
        @click="activeBtn = 2"
        class="btnR"
      >
        <span>安全管理</span>
        <i></i>
      </div>
    </div>
    <div v-if="activeBtn == 1" class="productList_1">
      <h2>Safety products</h2>
      <h3>安全产品</h3>
      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全产品icon.png"
          alt=""
        />
        <h2>智能控制柜</h2>
        <div @click="goDetails(1)">查看详情</div>
        <div @click="goDetails(1)">
          <img src="../../assets/product_center/安全产品示例图_1.png" alt="" />
        </div>
      </div>
      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全产品icon.png"
          alt=""
        />
        <h2>智能危险化学品储存柜</h2>
        <div @click="goDetails(2)">查看详情</div>
        <div @click="goDetails(2)">
          <img src="../../assets/product_center/安全产品示例图_2.png" alt="" />
        </div>
      </div>
      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全产品icon.png"
          alt=""
        />
        <h2>智能净气型化学品储存柜</h2>
        <div @click="goDetails(3)">查看详情</div>
        <div @click="goDetails(3)">
          <img src="../../assets/product_center/安全产品示例图_3.png" alt="" />
        </div>
      </div>

      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全产品icon.png"
          alt=""
        />
        <h2>手持盘存机</h2>
        <div @click="goDetails(4)">查看详情</div>
        <div @click="goDetails(4)">
          <img src="../../assets/product_center/安全产品示例图_4.png" alt="" />
        </div>
      </div>

      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全产品icon.png"
          alt=""
        />
        <h2>化学品重量数据传输模块</h2>
        <div @click="goDetails(5)">查看详情</div>
        <div @click="goDetails(5)">
          <img src="../../assets/product_center/安全产品示例图_5.png" alt="" />
        </div>
      </div>

      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全产品icon.png"
          alt=""
        />
        <h2>危险化学品电子天平</h2>
        <div @click="goDetails(6)">查看详情</div>
        <div @click="goDetails(6)">
          <img src="../../assets/product_center/安全产品示例图_6.png" alt="" />
        </div>
      </div>
    </div>

    <div v-if="activeBtn == 2" class="productList_2">
      <h2>Security management</h2>
      <h3>安全管理</h3>
      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全管理icon.png"
          alt=""
        />
        <h2>实验室安全培训学习及考试准入系统</h2>
        <div @click="goDetails('a')">查看详情</div>
        <div @click="goDetails('a')">
          <img src="../../assets/product_center/安全管理示例图_1.png" alt="" />
        </div>
      </div>
      <div>
        <img
          class="icon"
          src="../../assets/product_center/安全管理icon.png"
          alt=""
        />
        <h2>实验室安全检查系统</h2>
        <div @click="goDetails('b')">查看详情</div>
        <div @click="goDetails('b')">
          <img src="../../assets/product_center/安全管理示例图_2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  data() {
    return {
      activeBtn: 1,
    };
  },
  methods: {
    goDetails(v) {
      this.$bus.$emit("tableSwitch", 0);
      this.$nextTick(() => {
        if (typeof v == "number") {
          this.$router.push({
            path: `/productCenterDetails_${v}`,
          });
        } else {
          switch (v) {
            case "a":
              window.open("http://8.140.137.245:6060/");
              break;

            case "b":
              window.open("http://114.55.144.106:83/");
              break;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.productCenter {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/product_center/背景.png") 0 0/100% 100%
      no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .switchBtn {
    width: 1200px;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
    opacity: 1;
    display: flex;
    position: absolute;
    top: 385px;
    left: 360px;
    z-index: 9;
    div {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      span {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bolder;
        color: #333333;
      }
      i {
        width: 100%;
        height: 2px;
        background: #0078ff;
        box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
        opacity: 0.8;
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .activeBtn {
      span {
        color: #0078ff;
      }
      i {
        display: block;
      }
    }
  }
  @mixin com($h) {
    width: 1200px;
    height: $h;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    > h2 {
      height: 40px;
      font-size: 42px;
      font-family: Arial;
      font-weight: bold;
      color: #0078ff;
      line-height: 40px;
      position: absolute;
      top: 65px;
      left: 0;
    }
    > h3 {
      height: 23px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #0078ff;
      line-height: 23px;
      position: absolute;
      top: 119px;
      left: 0;
    }
  }
  .productList_1 {
    @include com(1900px);
    > div {
      width: 550px;
      height: 490px;
      position: absolute;

      &:nth-of-type(1) {
        top: 215px;
        left: 0;
      }
      &:nth-of-type(2) {
        top: 215px;
        right: 0;
      }
      &:nth-of-type(3) {
        top: 782px;
        left: 0;
      }
      &:nth-of-type(4) {
        top: 782px;
        right: 0;
      }
      &:nth-of-type(5) {
        top: 1345px;
        left: 0;
      }
      &:nth-of-type(6) {
        top: 1345px;
        right: 0;
      }
      .icon {
        width: 31px;
        height: 37px;
        position: absolute;
        top: 2.5px;
        left: 0;
      }
      h2 {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        position: absolute;
        line-height: 40px;
        top: 0;
        left: 45px;
      }
      div {
        &:nth-of-type(1) {
          width: 150px;
          height: 40px;
          background: #e3f0ff;
          border-radius: 20px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #0078ff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          &:hover {
            background: linear-gradient(90deg, #0078ff, #0090ff);
            color: #ffffff;
          }
        }
        &:nth-of-type(2) {
          width: 550px;
          height: 420px;
          background: #e3f0ff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          bottom: 0;
          left: 0;
          &:hover {
            background: #3393ff;
          }
        }
      }
    }
  }
  .productList_2 {
    @include com(879px);
    > div {
      width: 550px;
      height: 586px;
      position: absolute;
      top: 215px;
      &:nth-of-type(2n - 1) {
        left: 0;
      }
      &:nth-of-type(2n) {
        right: 0;
      }
      .icon {
        width: 38px;
        height: 37px;
        position: absolute;
        top: 0;
        left: 0;
      }
      h2 {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 37px;
        position: absolute;
        top: 0;
        left: 45px;
      }
      div {
        &:nth-of-type(1) {
          width: 150px;
          height: 40px;
          background: #e3f0ff;
          border-radius: 20px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #3393ff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 66px;
          left: 0;
          &:hover {
            background: linear-gradient(90deg, #0078ff, #0090ff);
            color: #ffffff;
          }
        }
        &:nth-of-type(2) {
          width: 550px;
          height: 420px;
          background: #e3f0ff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          bottom: 0;
          left: 0;
          &:hover {
            background: #3393ff;
          }
        }
      }
    }
  }
}
</style>