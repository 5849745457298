<template>
  <div class="customerCase">
    <div class="background">
      <h2>客户案例</h2>
    </div>
    <div class="content">
      <h2>Customer case</h2>
      <h3>客户案例</h3>
      <div class="customerList">
        <div
          @click="activeCustomerItem = 0"
          :class="{ activeCustomerItem: activeCustomerItem == 0 }"
          class="customerItem"
        >
          <img src="../../assets/customer_case/客户icon_1.png" alt="" />
          <span>郑州大学</span>
        </div>
        <div
          @click="activeCustomerItem = 1"
          :class="{ activeCustomerItem: activeCustomerItem == 1 }"
          class="customerItem"
        >
          <img src="../../assets/customer_case/客户icon_2.png" alt="" />
          <span>中原工学院</span>
        </div>
        <div
          @click="activeCustomerItem = 2"
          :class="{ activeCustomerItem: activeCustomerItem == 2 }"
          class="customerItem"
        >
          <img src="../../assets/customer_case/客户icon_3.png" alt="" />
          <span>信阳农林学院</span>
        </div>
        <div class="customerListBtnL" @click="prev()">
          <img src="../../assets/solution/切换按钮_左_1.png" alt="" />
          <!-- <img
            src="../../assets/solution/切换按钮_左_2.png"
            alt=""
          /> -->
        </div>
        <div class="customerListBtnR" @click="next()">
          <img src="../../assets/solution/切换按钮_右_1.png" alt="" />
          <!-- <img
            src="../../assets/solution/切换按钮_右_2.png"
            alt=""
          /> -->
        </div>
      </div>
      <div class="describe">
        <div>
          <div>
            <el-carousel
              height="495px"
              autoplay
              indicator-position="none"
              arrow="never"
              @change="customerItemChange"
              ref="carousel"
            >
              <el-carousel-item>
                <img
                  class="legend legend_1"
                  src="../../assets/customer_case/内容图例_1.png"
                  alt=""
                />
              </el-carousel-item>

              <el-carousel-item>
                <img
                  class="legend legend_1"
                  src="../../assets/customer_case/内容图例_2.png"
                  alt=""
                />
              </el-carousel-item>

              <el-carousel-item>
                <img
                  class="legend legend_1"
                  src="../../assets/customer_case/内容图例_3.png"
                  alt=""
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <i></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeCustomerItem: 0,
      customerListBtnLFalg: false,
      customerListBtnRFalg: false,
    };
  },
  methods: {
    customerItemChange(t, f) {
      this.activeCustomerItem = t;
    },
    prev() {
      if (this.activeCustomerItem > 0) {
        this.activeCustomerItem--;
      } else {
        this.activeCustomerItem = 3;
      }
    },
    next() {
      if (this.activeCustomerItem < 2) {
        this.activeCustomerItem++;
      } else {
        this.activeCustomerItem = 0;
      }
    },
  },
  watch: {
    activeCustomerItem() {
      this.$refs.carousel.setActiveItem(this.activeCustomerItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.customerCase {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/customer_case/背景.png") 0 0/100% 100%
      no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .content {
    width: 1200px;
    // height: 1824px;
    margin: 0 auto;
    position: relative;
    > h2 {
      font-size: 42px;
      font-family: Arial;
      font-weight: bold;
      color: #333333;
      position: absolute;
      top: 92px;
      left: 0;
    }
    > h3 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 146px;
      left: 0;
    }
    .customerList {
      width: 100%;
      height: 94px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      top: 221px;
      left: 0;
      .customerItem {
        width: 300px;
        height: 90px;
        background: #f3f5f8;
        border: 2px solid #e9eef4;
        margin-right: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
        img {
          margin-right: 24px;
        }
        span {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #000000;
        }
      }
      .activeCustomerItem {
        border: 2px solid #0078ff;
        box-shadow: 0px 0px 20px 0px rgba(51, 147, 255, 0.29);
        span {
          color: #3393ff;
        }
      }
      .customerListBtnL {
        width: 11px;
        height: 94px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
      }
      .customerListBtnR {
        width: 11px;
        height: 94px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .describe {
      width: 1200px;
      padding-top: 371px;
      > div {
        width: 100%;
        height: 495px;
        margin-bottom: 80px;
        position: relative;
        z-index: 1;
        > div {
          width: 1150px;
          height: 495px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          img {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        i {
          width: 550px;
          height: 420px;
          background-color: #3393ff;
          position: absolute;
          bottom: -38px;
          left: -44px;
          z-index: 0;
        }
      }
      h2 {
        width: 100%;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 21px;
        text-align: center;
        margin-bottom: 30px;
      }
      p {
        width: 100%;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #666666;
        line-height: 30px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>