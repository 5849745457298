<template>
  <div class="top">
    <img src="../../../assets/home/logo.png" alt="logo" />
    <div class="tabs">
      <div :class="{ active: activeTab == 1 }">
        <span @click="tableSwitch(1)">首页</span>
        <i></i>
      </div>

      <div :class="{ active: activeTab == 2 }">
        <span @click="tableSwitch(2)">产品中心</span>
        <i></i>
      </div>

      <div :class="{ active: activeTab == 3 }">
        <span @click="tableSwitch(3)">解决方案</span>
        <i></i>
      </div>

      <div :class="{ active: activeTab == 4 }">
        <span @click="tableSwitch(4)">服务支持</span>
        <i></i>
      </div>

      <div :class="{ active: activeTab == 5 }">
        <span @click="tableSwitch(5)">客户案例</span>
        <i></i>
      </div>

      <div :class="{ active: activeTab == 6 }">
        <span @click="tableSwitch(6)">关于我们</span>
        <i></i>
      </div>
    </div>
    <div class="contact">
      <img src="../../../assets/home/电话.png" alt="电话" />
      <div>13693717018</div>
      <div>0371-86231817</div>
    </div>
    <div class="contact_f">
      <div @click="qqContact()">
        <div>
          <div>
            <i class="icon_1"></i>
          </div>
        </div>
        <div>
          <div style="width: 100px">联系我们</div>
        </div>
      </div>

      <div @click="wxContact()">
        <div>
          <div>
            <i class="icon_2"></i>
          </div>
        </div>
        <div>
          <div style="width: 100px">联系我们</div>
        </div>
      </div>

      <div @click="telephoneContact()">
        <div>
          <div>
            <i class="icon_3"></i>
          </div>
        </div>
        <div>
          <div>联系电话：13693717018 / 0371-86231817</div>
        </div>
      </div>
    </div>
    <!-- 二维码 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="657px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="ewmBox">
        <img class="ewm" src="../../../assets/home/二维码.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$bus.$off("tableSwitch").$on("tableSwitch", (v) => {
      this.tableSwitch(v);
    });
    this.$bus.$off("resetRouter").$on("resetRouter", () => {
      this.resetRouter();
    });
    this.$bus.$off("wxContact").$on("wxContact", () => {
      this.wxContact();
    });
    this.$bus.$off("qqContact").$on("qqContact", () => {
      this.qqContact();
    });
    this.resetRouter();
  },
  data() {
    return {
      activeTab: 1,
      dialogVisible: false, // 是否显示二维码
    };
  },
  methods: {
    // 导航标签切换
    tableSwitch(v) {
      this.activeTab = v;
    },
    // 非正常进入页面时重置导航状态
    resetRouter() {
      switch (this.$route.path) {
        case "/":
          this.activeTab = 1;
          break;
        case "/ProductCenter":
          this.activeTab = 2;
          break;
        case "/solution":
          this.activeTab = 3;
          break;
        case "/erviceSupport":
          this.activeTab = 4;
          break;
        case "/customerCase":
          this.activeTab = 5;
          break;
        case "/aboutUs":
          this.activeTab = 6;
          break;
      }
    },
    // qq联系
    qqContact() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=2215003827&site=qq&menu=yes"
      );
    },
    // 微信联系
    wxContact() {
      this.dialogVisible = true;
    },
    // 电话联系
    telephoneContact() {},
  },
  watch: {
    activeTab() {
      switch (this.activeTab) {
        case 1:
          this.$route.path != "/" ? this.$router.push("/") : "";
          break;
        case 2:
          this.$route.path != "/ProductCenter"
            ? this.$router.push("/ProductCenter")
            : "";
          break;
        case 3:
          this.$route.path != "/solution" ? this.$router.push("/solution") : "";
          break;
        case 4:
          this.$route.path != "/erviceSupport"
            ? this.$router.push("/erviceSupport")
            : "";
          break;
        case 5:
          this.$route.path != "/customerCase"
            ? this.$router.push("/customerCase")
            : "";
          break;
        case 6:
          this.$route.path != "/aboutUs" ? this.$router.push("/aboutUs") : "";
          break;
      }
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  height: 80px;
  > img {
    width: 212px;
    height: 33px;
    position: absolute;
    top: 24px;
    left: 58px;
  }
  .tabs {
    height: 16px;
    position: absolute;
    top: 33px;
    left: 813px;

    div {
      margin: 0 40px;
      display: inline-block;
      position: relative;
      span {
        vertical-align: top;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 16px;
        cursor: pointer;
      }
      i {
        display: none;
        width: 100%;
        height: 2px;
        background: #0078ff;
        position: absolute;
        top: 37px;
        left: 0px;
      }
    }
    .active {
      span {
        color: #0078ff;
      }
      i {
        display: block;
      }
    }
    div:nth-of-type(1) {
      margin-left: 0;
    }
    div:nth-last-of-type() {
      margin-right: 0;
    }
  }
  .contact {
    font-size: 0;
    width: 180px;
    height: 50px;
    border: 1px solid #0078ff;
    border-radius: 25px;
    position: absolute;
    top: 16px;
    right: 58px;
    img {
      width: 21px;
      height: 23px;
      position: absolute;
      top: 16px;
      left: 13px;
    }
    div:nth-of-type(1) {
      height: 13px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0078ff;
      line-height: 13px;
      position: absolute;
      top: 9px;
      left: 47px;
    }
    div:nth-of-type(2) {
      height: 13px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0078ff;
      line-height: 13px;
      position: absolute;
      top: 29px;
      left: 46px;
    }
  }
  .contact_f {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    top: 145px;
    right: 0;
    z-index: 9;
    > div {
      transition: 0.25s;
      width: 50px;
      height: 50px;
      margin-bottom: 3px;
      background-color: #00addf;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:hover {
        transition: 0.25s;
        width: 150px;
      }
      &:nth-last-of-type(1) {
        &:hover {
          width: 375px;
        }
      }
      > div {
        &:nth-of-type(1) {
          width: 50px;
          height: 50px;
          > div {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &:nth-of-type(2) {
          > div {
            width: 325px;
            height: 50px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .icon_1 {
        width: 25px;
        height: 28px;
        background: url("../../../assets/home/联系Icon精灵图.png") 0 0 no-repeat;
      }
      .icon_2 {
        width: 25px;
        height: 25px;
        background: url("../../../assets/home/联系Icon精灵图.png") 0 -30px no-repeat;
      }
      .icon_3 {
        width: 25px;
        height: 32px;
        background: url("../../../assets/home/联系Icon精灵图.png") 0 -60px no-repeat;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.top {
  ::v-deep .el-icon-close {
    position: relative;
    z-index: 1;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
}
.ewmBox {
  width: 657px;
  height: 771px;
}
.ewm {
  width: 657px;
  height: 771px;
}
</style>