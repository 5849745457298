<template>
  <div class="productCenterDetails">
    <div class="background">
      <h2>产品中心</h2>
    </div>
    <div class="breadcrumb">
      <img
        src="../../assets/product_center_details/产品中心home_icon.png"
        alt=""
      />
      <span @click="tableSwitch(1)">首页</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span @click="tableSwitch(2)">产品中心</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span>化学品重量数据传输模块</span>
    </div>
    <div class="contentBox">
      <h2>化学品重量数据传输模块</h2>
      <img src="../../assets/product_center_details/标题装饰.png" alt="" />
      <div></div>
      <img src="../../assets/product_center_details/示例图_5.png" alt="" />
      <el-row class="mb-15">
        <el-col class="flex jc-sb">
          <el-image
            v-for="(v, k) in imgArr"
            :key="'imgArr' + k"
            style="width: 23%"
            class="titleImg"
            :src="v"
            :preview-src-list="imgArr"
          ></el-image>
        </el-col>
      </el-row>
      <p class="introduce" v-html="'型号：JELON D-01'"></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;数据传输终端通过高性能嵌入式软件自动控制，自动维护无线连接，自动数据采集，可以实现真正的无人值守，实现远程对设备数据采集，遥测，遥控和告警，运用于危险化学品入库、出库及使用过程中重量的实时传输。支持透明传输 /HTTP 协议/MQTT 私有云/阿里云/百度云/中移 Onenet/短信，可以简单快速的连接多家物联网云平台及现有平台，稳定简单易用。'
        "
      ></p>
      <p class="introduce" v-html="'基础功能及特点：'"></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;1、功耗低，内置低功耗高性能的嵌入式处理器；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;2、 多重软硬件可靠设计，复合式看门狗技术，使设备安全运行；'
        "
      ></p>
      <p
        class="introduce"
        v-html="'&nbsp;&nbsp;&nbsp;&nbsp;3、防浪涌设计,防反接保护；'"
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;4、支持 RS232+RS485/TTL 硬件串口接口；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;5、支持 GNSS（北斗+GPS）定位（选配）；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;6、支持多种工作模式，在线模式和调试模式；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;7、支持多路中心地址，4G 可同时连接 4 路中心服务器,2G 可以同时连接 2 路；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;8、 内嵌 TCP/UDP/DNS/PPP/HTTP/MQTT 等协议栈；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;9、支持连接阿里/中移/百度/私有 MQTT 云等物联平台；'
        "
      ></p>
      <p
        class="introduce"
        v-html="'&nbsp;&nbsp;&nbsp;&nbsp;10、优化网络协议栈，快速连接服务器；'"
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;11、 极速响应，连续快速稳定传输，不掉线；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;12、超大缓存，可连续大数据传输，不丢包；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;13、支持域名和 IP 两种连接服务器方式；'
        "
      ></p>
      <p
        class="introduce"
        v-html="'&nbsp;&nbsp;&nbsp;&nbsp;14、支持心跳包功能，保证可靠连接；'"
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;15、支持注册包功能，连接上服务器则首先发送指定数据包，方便服务器对终端的识别；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;16、支持 AT 命令、短消息命令、服务器下发命令三种方式对 DTU 进行参数配置和查询；'
        "
      ></p>
      <p
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;17、支持多组采集指令自主配置和周期轮询；'
        "
      ></p>
      <p
        class="introduce"
        v-html="'&nbsp;&nbsp;&nbsp;&nbsp;18、支持配置参数掉电保存；'"
      ></p>
      <p
        class="introduce"
        v-html="'&nbsp;&nbsp;&nbsp;&nbsp;19、支持程序远程升级；'"
      ></p>
      <p
        class="introduce"
        v-html="'&nbsp;&nbsp;&nbsp;&nbsp;20、网络状态灯指示；'"
      ></p>
      <p
        style="margin-bottom: 60px"
        class="introduce"
        v-html="
          '&nbsp;&nbsp;&nbsp;&nbsp;21、工业级标准设计，工作温度-40-+85度；'
        "
      ></p>
    </div>
  </div>
</template>

 <script>
export default {
  data() {
    return {
      activeNavItem: 1,
      imgArr: [
        require("../../assets/product_center_details/示例图_5-1.png"),
        require("../../assets/product_center_details/示例图_5-2.png"),
        require("../../assets/product_center_details/示例图_5-3.png"),
        require("../../assets/product_center_details/示例图_5-4.png"),
      ],
    };
  },
  methods: {
    tableSwitch(v) {
      this.$bus.$emit("tableSwitch", v);
    },
  },
};
</script>

 <style lang="scss" scoped>
.productCenterDetails {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/product_center/背景.png") 0 0/100% 100%
      no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .breadcrumb {
    width: 1200px;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
    opacity: 0.8;
    display: flex;
    align-items: center;
    position: absolute;
    top: 385px;
    left: 360px;
    z-index: 9;
    img {
      width: 26px;
      height: 26px;
      margin-left: 26px;
      margin-right: 20px;
    }
    span {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      &:nth-of-type(1),
      &:nth-of-type(3) {
        cursor: pointer;
      }
      &:nth-of-type(5) {
        color: #0078ff;
      }
    }
  }
  .contentBox {
    width: 1200px;
    // height: 7746px;
    margin: 0 auto;
    text-align: center;
    h2 {
      &:nth-of-type(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 30px;
        margin-top: 115px;
      }
    }
    img {
      &:nth-of-type(1) {
        width: 220px;
        height: 3px;
        margin-top: 19px;
        margin-bottom: 40px;
      }
      &:nth-of-type(2) {
        margin-bottom: 55px;
      }
    }
    p {
      width: 100%;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
      margin-bottom: 60px;
    }
    .title {
      margin-bottom: 39px;
    }
    .example {
      margin-bottom: 40px;
    }
    .introduce {
      text-align: left;
      margin-bottom: 0px;
    }
  }
  .navMenu {
    width: 120px;
    height: 900px;
    background: #f1f8ff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.13);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 459px;
    left: 102px;
    .navItem {
      width: 120px;
      height: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .decorate {
        width: 78px;
        height: 1px;
        background: #dcdcdc;
        position: absolute;
        top: -0.5px;
        left: 21px;
      }
      img {
        position: relative;
        top: -15px;
        &:nth-of-type(1) {
          display: block;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
      span {
        width: 100%;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 65px;
        left: 0;
      }
    }
    .activeNavItem {
      background: #0078ff;
      .decorate {
        display: none;
      }
      img {
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
      span {
        color: #ffffff;
      }
    }
  }
}

</style>