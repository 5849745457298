export let loginWS = function(websocket, bus) {
  websocket.onerror = function() {
    // console.log("WebSocket连接发生错误");
  };

  //连接成功建立的回调方法
  websocket.onopen = function() {
    // console.log("WebSocket连接成功");
    // websocket.send("测试");
  };

  //接收到消息的回调方法
  websocket.onmessage = function(event) {
    let data = JSON.parse(event.data);
    // code 命令类型
    // type 2主柜 / 1控制柜
    // data 更新编号 主柜/控制柜编号
    switch (String(data.code)) {
      // 1:温度变化 2:湿度变化 3:tvoc浓度变化 4:操作数(登录)变化 5:借出数变化 6:归还数变化 7:报警数变化
      case "1":
        // console.log("温度变化", data);
        bus.$emit("温度变化");
        break;
      case "2":
        // console.log("湿度变化", data);
        bus.$emit("湿度变化");
        break;
      case "3":
        // console.log("tvoc浓度变化", data);
        bus.$emit("tvoc浓度变化");
        break;
      case "4":
        // console.log("操作数(登录)变化", data);
        bus.$emit("4", data);
        break;
      case "8":
        // console.log("主柜状态变化", data);
        bus.$emit("8", data);
        break;
    }
  };

  //连接关闭的回调方法
  websocket.onclose = function() {
    // console.log("WebSocket连接关闭");
    setTimeout(() => {
      websocket = new WebSocket("ws://8.140.137.245:9099/socket/001");
      loginWS(websocket, bus);
    }, 3000);
  };

  //监听窗口关闭事件，当窗口关闭时，主动去关闭WebSocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
  window.addEventListener("beforeunload", () => {
    // console.log("页面关闭");
    websocket.close();
  });
};

export let loginWSC = function(websocket) {
  websocket.close();
};
