<template>
  <div class="productCenterDetails">
    <div class="background">
      <h2>产品中心</h2>
    </div>
    <div class="breadcrumb">
      <img
        src="../../assets/product_center_details/产品中心home_icon.png"
        alt=""
      />
      <span @click="tableSwitch(1)">首页</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span @click="tableSwitch(2)">产品中心</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span>智能净气型危险化学品储存柜</span>
    </div>
    <div class="contentBox">
      <h2>智能净气型危险化学品储存柜</h2>
      <img src="../../assets/product_center_details/标题装饰.png" alt="" />
      <p>
        智能净气型化学品储存柜（JELON
        B-01/04/06）是一款多功能的智能化学品储存柜，具有智能危险化学品储存柜的全部功能，同时为实验室内部所有用户以及与实验室外部环境相关的所有人员提供安全保证，配置了智能挥发性气体化学吸附过滤净化模块，无需架设管道，不排放化学污染，将酸性、碱性、有机挥发物及有害粉尘粒子吸附净化，实现零排放，为室内、室外环境提供洁净的空气。
      </p>
      <img src="../../assets/product_center_details/示例图_3.png" alt="" />
      <h2 class="title">柜体</h2>
      <div id="1" ref="paragraph_1" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/17.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_10.png"
            alt=""
          />
          <p>
            柜体采用双层冷轧钢板，符合防火防爆规范要求；防静电接地装置，标准的泄压口；优质稳固的福马轮，便于移动及稳定放置；配置电控双锁符合管控规范；配备停电应急电源（UPS电源）、应急钥匙开锁、应急电源接口；根据需要可选配两门、四门、六门柜体
          </p>
        </div>
      </div>
      <h2 class="title">内胆及层板</h2>
      <div id="2" ref="paragraph_2" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/10.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_11.png"
            alt=""
          />
          <p>
            柜内配置一次成型PP材质内胆及层板，可以根据需要调节托盘层板之间距离，满足防腐及承重要求
          </p>
        </div>
      </div>
      <h2 class="title">控制系统</h2>
      <div id="3" ref="paragraph_3" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/11.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_8.png"
            alt=""
          />
          <p>
            集成安卓工控触摸一体机，实时监测显示柜内温湿度、TVOC浓度，门锁及风机状态，各类违规操作实时语音文字报警及报警状态显示上报
          </p>
        </div>
      </div>
      <h2 class="title">摄像头</h2>
      <div id="4" ref="paragraph_4" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/12.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_13.png"
            alt=""
          />
          <p>
            安装智能安防网络摄像头，实时监测存储每次打开柜门的影像资料，可本地及远程查看及下载
          </p>
        </div>
      </div>
      <h2 class="title">吸附净化模块</h2>
      <div id="5" ref="paragraph_5" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/18.png"
          alt=""
        />
        <div class="introduce">
          <img
            class="imgNoMb"
            src="../../assets/product_center_details/标题icon_16.png"
            alt=""
          />
          <p>
            TVOC、酸、碱等有害挥发气体实时自动监测，配备挥发性气体化学吸附过滤净化模块，无需架设管道，不排放化学污染，将酸性、碱性、有机挥发物及有害粉尘粒子吸附净化，实现零排放；配备双气体检测器，实时检测吸附过滤净化前后的挥发的有害气体浓度，自主知识产权的智能控制系统，实时显示吸附过滤净化模块过滤效率及更换时间
          </p>
        </div>
      </div>
      <h2 class="title">RFID识别</h2>
      <div id="6" ref="paragraph_6" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/14.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_4.png"
            alt=""
          />
          <p>RFID试剂智能识别、智能实时盘点</p>
        </div>
      </div>
      <h2 class="title">手机控制</h2>
      <div id="7" ref="paragraph_7" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/15.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_15.png"
            alt=""
          />
          <p>
            手机端、电脑端双系统管理，可以实现远程柜门控制，可以移动端查看库存、使用情况及违规操作报警
          </p>
        </div>
      </div>
      <h2 class="title">库存账表</h2>
      <div id="8" ref="paragraph_8" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/16.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_4.png"
            alt=""
          />
          <p>
            强大的前端、后台管理功能，自动生成库存台账、使用台账及各类报表，方便数据下载导出
          </p>
        </div>
      </div>
    </div>
    <div ref="navMenu" class="navMenu">
      <div
        @click="
          activeNavItem = 1;
          goAnchor(1);
        "
        :class="{ activeNavItem: activeNavItem == 1 }"
        class="navItem"
      >
        <img
          src="../../assets/product_center_details/导航_10_柜体_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_10_柜体_2.png"
          alt=""
        />
        <span>柜体</span>
      </div>

      <div
        @click="
          activeNavItem = 2;
          goAnchor(2);
        "
        :class="{ activeNavItem: activeNavItem == 2 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_11_内胆及层板_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_11_内胆及层板_2.png"
          alt=""
        />
        <span>内胆及层板</span>
      </div>

      <div
        @click="
          activeNavItem = 3;
          goAnchor(3);
        "
        :class="{ activeNavItem: activeNavItem == 3 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_8_楼宇自控制系统_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_8_楼宇自控制系统_2.png"
          alt=""
        />
        <span>控制系统</span>
      </div>

      <div
        @click="
          activeNavItem = 4;
          goAnchor(4);
        "
        :class="{ activeNavItem: activeNavItem == 4 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_13_摄像头_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_13_摄像头_2.png"
          alt=""
        />
        <span>摄像头</span>
      </div>

      <div
        @click="
          activeNavItem = 5;
          goAnchor(5);
        "
        :class="{ activeNavItem: activeNavItem == 5 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_16_吸附净化模块_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_16_吸附净化模块_2.png"
          alt=""
        />
        <span>吸附净化模块</span>
      </div>

      <div
        @click="
          activeNavItem = 6;
          goAnchor(6);
        "
        :class="{ activeNavItem: activeNavItem == 6 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_5_RFID标签日志_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_5_RFID标签日志_2.png"
          alt=""
        />
        <span>RFID识别</span>
      </div>

      <div
        @click="
          activeNavItem = 7;
          goAnchor(7);
        "
        :class="{ activeNavItem: activeNavItem == 7 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_15_手机控制_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_15_手机控制_2.png"
          alt=""
        />
        <span>手机控制</span>
      </div>

      <div
        @click="
          activeNavItem = 8;
          goAnchor(8);
        "
        :class="{ activeNavItem: activeNavItem == 8 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_4_库存报表_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_4_库存报表_2.png"
          alt=""
        />
        <span>库存账表</span>
      </div>

      <a href="#" class="navItem">
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_9_返回顶部_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_9_返回顶部_2.png"
          alt=""
        />
        <span>返回顶部</span>
      </a>
    </div>
  </div>
</template>

 <script>
export default {
  mounted() {
    window.document.onscroll = () => {
      this.getScrollPosition();
    };
  },
  destroyed() {
    window.document.onscroll = null;
  },
  data() {
    return {
      activeNavItem: 0,
      navMenuFalg: true, // 导航栏定位模式切换辅助变量
    };
  },
  methods: {
    tableSwitch(v) {
      this.$bus.$emit("tableSwitch", v);
    },
    // 跳转到锚点
    goAnchor(v) {
      // window.location.href = `#${v}`;
      console.log(v, this.$refs[`paragraph_${v}`]);
      let el = this.$refs[`paragraph_${v}`];
      let scrollTop =
        80 +
        el.offsetTop +
        el.offsetHeight -
        document.documentElement.clientHeight;
      console.log(
        scrollTop,
        el.offsetTop,
        document.documentElement.clientHeight
      );
      if (scrollTop >= 0) {
        document.documentElement.scrollTop = scrollTop;
      }
    },
    // 获取当前滚动条的位置
    getScrollPosition() {
      let dom = this.$refs.navMenu;
      let sT = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        sT = document.documentElement.scrollTop;
      }
      if (sT >= 500 && this.navMenuFalg) {
        this.navMenuFalg = false;
        dom.style.position = "fixed";
        dom.style.top = "25px";
      } else {
        if (sT < 500 && !this.navMenuFalg) {
          this.navMenuFalg = true;
          dom.style.position = "absolute";
          dom.style.top = "450px";
        }
      }
      let item = 0;
      for (let i = 1; i < 9; i++) {
        this.isElementInViewport(this.$refs["paragraph_" + i])
          ? i > item
            ? (item = i)
            : ""
          : "";
      }
      this.activeNavItem != item ? (this.activeNavItem = item) : "";
      // if (sT >= 4160) {
      //   this.activeNavItem == 8 ? "" : (this.activeNavItem = 8);
      // } else if (sT >= 4044) {
      //   this.activeNavItem == 7 ? "" : (this.activeNavItem = 7);
      // } else if (sT >= 3064) {
      //   this.activeNavItem == 6 ? "" : (this.activeNavItem = 6);
      // } else if (sT >= 3164) {
      //   this.activeNavItem == 5 ? "" : (this.activeNavItem = 5);
      // } else if (sT >= 2724) {
      //   this.activeNavItem == 4 ? "" : (this.activeNavItem = 4);
      // } else if (sT >= 2284) {
      //   this.activeNavItem == 3 ? "" : (this.activeNavItem = 3);
      // } else if (sT >= 1844) {
      //   this.activeNavItem == 2 ? "" : (this.activeNavItem = 2);
      // } else if (sT >= 1404) {
      //   this.activeNavItem == 1 ? "" : (this.activeNavItem = 1);
      // } else {
      //   this.activeNavItem == 0 ? "" : (this.activeNavItem = 0);
      // }
    },
    isElementInViewport(el) {
      const box = el.getBoundingClientRect(),
        top = box.top >= 0,
        left = box.left >= 0,
        bottom =
          box.bottom <=
          (window.innerHeight || document.documentElement.clientHeight),
        right =
          box.right <=
          (window.innerWidth || document.documentElement.clientWidth);
      return top && left && bottom && right;
    },
  },
};
</script>

 <style lang="scss" scoped>
.productCenterDetails {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/product_center/背景.png") 0 0/100% 100%
      no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .breadcrumb {
    width: 1200px;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
    opacity: 0.8;
    display: flex;
    align-items: center;
    position: absolute;
    top: 385px;
    left: 360px;
    z-index: 9;
    img {
      width: 26px;
      height: 26px;
      margin-left: 26px;
      margin-right: 20px;
    }
    span {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      &:nth-of-type(1),
      &:nth-of-type(3) {
        cursor: pointer;
      }
      &:nth-of-type(5) {
        color: #0078ff;
      }
    }
  }
  .contentBox {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    h2 {
      &:nth-of-type(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 30px;
        margin-top: 115px;
      }
    }
    > img {
      &:nth-of-type(1) {
        width: 220px;
        height: 3px;
        margin-top: 19px;
        margin-bottom: 40px;
      }
      &:nth-of-type(2) {
        margin-bottom: 55px;
      }
    }
    > p {
      width: 100%;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      text-align: left;
      text-indent: 36px;
      color: #666666;
      line-height: 36px;
      margin-bottom: 60px;
    }
    .title {
      margin-bottom: 39px;
    }
    .paragraph {
      width: 100%;
      height: 380px;
      background: #f1f8ff;
      margin-bottom: 60px;
      font-size: 0;
      position: relative;
      .example {
        width: 780px;
        height: 380px;
        position: absolute;
        top: 0;
        right: 0;
      }
      .introduce {
        width: calc(100% - 780px);
        height: 380px;
        background: #f1f8ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        img {
          margin-bottom: 40px;
        }
        .imgNoMb {
          margin-bottom: 0;
        }
        p {
          width: 361px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
          text-align: left;
        }
      }
      &:nth-of-type(2n) {
        background: #f6f6f6;
        .example {
          left: 0;
        }
        .introduce {
          left: auto;
          right: 0;
        }
      }
    }
  }
  .navMenu {
    width: 120px;
    height: 900px;
    background: #f1f8ff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.13);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 450px;
    left: 102px;
    z-index: 21;
    .navItem {
      width: 120px;
      height: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .decorate {
        width: 78px;
        height: 1px;
        background: #dcdcdc;
        position: absolute;
        top: -0.5px;
        left: 21px;
      }
      img {
        position: relative;
        top: -15px;
        &:nth-of-type(1) {
          display: block;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
      span {
        width: 100%;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 65px;
        left: 0;
      }
    }
    .activeNavItem {
      background: #0078ff;
      .decorate {
        display: none;
      }
      img {
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
      span {
        color: #ffffff;
      }
    }
  }
}
</style>