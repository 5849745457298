<template>
  <div class="productCenterDetails">
    <div class="background">
      <h2>产品中心</h2>
    </div>
    <div class="breadcrumb">
      <img
        src="../../assets/product_center_details/产品中心home_icon.png"
        alt=""
      />
      <span @click="tableSwitch(1)">首页</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span @click="tableSwitch(2)">产品中心</span>
      <span v-html="'&nbsp;>&nbsp;'"></span>
      <span>智能控制柜</span>
    </div>
    <div class="contentBox">
      <h2>智能控制柜</h2>
      <img src="../../assets/product_center_details/标题装饰.png" alt="" />
      <p>
        智能控制柜（JELON
        A）是一款全智能的危险化学品控制管理终端，集成了双人身份认证（人脸+刷卡+指纹）、智能RFID识别技术、自动称重系统，自动进行双人身份认证、自动识别试剂及自动称重上传试剂使用重量；自动查询柜内试剂及库存量，自动语音报警各类违规操作。
      </p>
      <img src="../../assets/product_center_details/示例图_1.png" alt="" />
      <h2 class="title">触摸屏</h2>
      <div id="1" ref="paragraph_1" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/1.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_1.png"
            alt=""
          />
          <p>友好的人机界面，13.3寸电容触摸屏，便于操作</p>
        </div>
      </div>
      <h2 class="title">人脸识别</h2>
      <div id="2" ref="paragraph_2" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/2.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_6.png"
            alt=""
          />
          <p>双人身份认证（人脸识别、指纹、刷卡）登录管理</p>
        </div>
      </div>
      <h2 class="title">RFID识别</h2>
      <div id="3" ref="paragraph_3" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/3.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_5.png"
            alt=""
          />
          <p>智能RFID射频识别试剂</p>
        </div>
      </div>
      <h2 class="title">称重</h2>
      <div id="4" ref="paragraph_4" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/4.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_7.png"
            alt=""
          />
          <p>自动称重功能，实时记录试剂使用量</p>
        </div>
      </div>

      <h2 class="title">控制系统</h2>
      <div id="5" ref="paragraph_5" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/5.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_8.png"
            alt=""
          />
          <p>功能齐全，入库、借出、归还、注销操作准确快捷</p>
        </div>
      </div>

      <h2 class="title">报警</h2>
      <div id="6" ref="paragraph_6" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/6.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_3.png"
            alt=""
          />
          <p>实时语音文字报警，让操作者及时纠正违规操作</p>
        </div>
      </div>
      <h2 class="title">网络</h2>
      <div id="7" ref="paragraph_7" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/7.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_2.png"
            alt=""
          />
          <p>
            可以通过局域网、WIFI、4G/5G控制智能危险化学品储存柜（无数量上限）
          </p>
        </div>
      </div>
      <h2 class="title">库存账表</h2>
      <div id="8" ref="paragraph_8" class="paragraph">
        <img
          class="example"
          src="../../assets/product_center_details/8.png"
          alt=""
        />
        <div class="introduce">
          <img
            src="../../assets/product_center_details/标题icon_4.png"
            alt=""
          />
          <p>
            强大的软件功能，与管理后台实时通讯，查询化学试剂库存，上传记录所有操作信息
          </p>
        </div>
      </div>
    </div>
    <div ref="navMenu" class="navMenu">
      <div
        @click="
          activeNavItem = 1;
          goAnchor(1);
        "
        :class="{ activeNavItem: activeNavItem == 1 }"
        class="navItem"
      >
        <img
          src="../../assets/product_center_details/导航_1_面板触摸屏_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_1_面板触摸屏_2.png"
          alt=""
        />
        <span>触摸屏</span>
      </div>

      <div
        @click="
          activeNavItem = 2;
          goAnchor(2);
        "
        :class="{ activeNavItem: activeNavItem == 2 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_6_人脸识别_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_6_人脸识别_2.png"
          alt=""
        />
        <span>人脸识别</span>
      </div>

      <div
        @click="
          activeNavItem = 3;
          goAnchor(3);
        "
        :class="{ activeNavItem: activeNavItem == 3 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_5_RFID标签日志_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_5_RFID标签日志_2.png"
          alt=""
        />
        <span>RFID识别</span>
      </div>

      <div
        @click="
          activeNavItem = 4;
          goAnchor(4);
        "
        :class="{ activeNavItem: activeNavItem == 4 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_7_称重_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_7_称重_2.png"
          alt=""
        />
        <span>称重</span>
      </div>

      <div
        @click="
          activeNavItem = 5;
          goAnchor(5);
        "
        :class="{ activeNavItem: activeNavItem == 5 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_8_楼宇自控制系统_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_8_楼宇自控制系统_2.png"
          alt=""
        />
        <span>控制系统</span>
      </div>

      <div
        @click="
          activeNavItem = 6;
          goAnchor(6);
        "
        :class="{ activeNavItem: activeNavItem == 6 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_3_报警_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_3_报警_2.png"
          alt=""
        />
        <span>报警</span>
      </div>

      <div
        @click="
          activeNavItem = 7;
          goAnchor(7);
        "
        :class="{ activeNavItem: activeNavItem == 7 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_2_网络_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_2_网络_2.png"
          alt=""
        />
        <span>网络</span>
      </div>

      <div
        @click="
          activeNavItem = 8;
          goAnchor(8);
        "
        :class="{ activeNavItem: activeNavItem == 8 }"
        class="navItem"
      >
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_4_库存报表_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_4_库存报表_2.png"
          alt=""
        />
        <span>库存账表</span>
      </div>

      <a href="#" class="navItem">
        <div class="decorate"></div>
        <img
          src="../../assets/product_center_details/导航_9_返回顶部_1.png"
          alt=""
        />
        <img
          src="../../assets/product_center_details/导航_9_返回顶部_2.png"
          alt=""
        />
        <span>返回顶部</span>
      </a>
    </div>
  </div>
</template>

 <script>
export default {
  mounted() {
    window.document.onscroll = () => {
      this.getScrollPosition();
    };
  },
  destroyed() {
    window.document.onscroll = null;
  },
  data() {
    return {
      activeNavItem: 0,
      navMenuFalg: true, // 导航栏定位模式切换辅助变量
    };
  },
  methods: {
    tableSwitch(v) {
      this.$bus.$emit("tableSwitch", v);
    },
    // 跳转到锚点
    goAnchor(v) {
      // window.location.href = `#${v}`;
      console.log(v, this.$refs[`paragraph_${v}`]);
      let el = this.$refs[`paragraph_${v}`];
      let scrollTop =
        80 +
        el.offsetTop +
        el.offsetHeight -
        document.documentElement.clientHeight;
      console.log(
        scrollTop,
        el.offsetTop,
        document.documentElement.clientHeight
      );
      if (scrollTop >= 0) {
        document.documentElement.scrollTop = scrollTop;
      }
    },
    // 获取当前滚动条的位置
    getScrollPosition() {
      let dom = this.$refs.navMenu;
      let sT = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        sT = document.documentElement.scrollTop;
      }
      if (sT >= 500 && this.navMenuFalg) {
        this.navMenuFalg = false;
        dom.style.position = "fixed";
        dom.style.top = "25px";
      } else {
        if (sT < 500 && !this.navMenuFalg) {
          this.navMenuFalg = true;
          dom.style.position = "absolute";
          dom.style.top = "450px";
        }
      }
      let item = 0;
      for (let i = 1; i < 9; i++) {
        this.isElementInViewport(this.$refs["paragraph_" + i])
          ? i > item
            ? (item = i)
            : ""
          : "";
      }
      this.activeNavItem != item ? (this.activeNavItem = item) : "";
      // if (sT >= 4160) {
      //   this.activeNavItem == 8 ? "" : (this.activeNavItem = 8);
      // } else if (sT >= 4044) {
      //   this.activeNavItem == 7 ? "" : (this.activeNavItem = 7);
      // } else if (sT >= 3064) {
      //   this.activeNavItem == 6 ? "" : (this.activeNavItem = 6);
      // } else if (sT >= 3164) {
      //   this.activeNavItem == 5 ? "" : (this.activeNavItem = 5);
      // } else if (sT >= 2724) {
      //   this.activeNavItem == 4 ? "" : (this.activeNavItem = 4);
      // } else if (sT >= 2284) {
      //   this.activeNavItem == 3 ? "" : (this.activeNavItem = 3);
      // } else if (sT >= 1844) {
      //   this.activeNavItem == 2 ? "" : (this.activeNavItem = 2);
      // } else if (sT >= 1404) {
      //   this.activeNavItem == 1 ? "" : (this.activeNavItem = 1);
      // } else {
      //   this.activeNavItem == 0 ? "" : (this.activeNavItem = 0);
      // }
      // console.log(sT);
    },
    isElementInViewport(el) {
      const box = el.getBoundingClientRect(),
        top = box.top >= 0,
        left = box.left >= 0,
        bottom =
          box.bottom <=
          (window.innerHeight || document.documentElement.clientHeight),
        right =
          box.right <=
          (window.innerWidth || document.documentElement.clientWidth);
      return top && left && bottom && right;
    },
  },
};
</script>

 <style lang="scss" scoped>
.productCenterDetails {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/product_center/背景.png") 0 0/100% 100%
      no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .breadcrumb {
    width: 1200px;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
    opacity: 0.8;
    display: flex;
    align-items: center;
    position: absolute;
    top: 385px;
    left: 360px;
    z-index: 9;
    img {
      width: 26px;
      height: 26px;
      margin-left: 26px;
      margin-right: 20px;
    }
    span {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      &:nth-of-type(1),
      &:nth-of-type(3) {
        cursor: pointer;
      }
      &:nth-of-type(5) {
        color: #0078ff;
      }
    }
  }
  .contentBox {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    h2 {
      &:nth-of-type(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 30px;
        margin-top: 115px;
      }
    }
    > img {
      &:nth-of-type(1) {
        width: 220px;
        height: 3px;
        margin-top: 19px;
        margin-bottom: 40px;
      }
      &:nth-of-type(2) {
        margin-bottom: 55px;
      }
    }
    > p {
      width: 100%;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      text-align: left;
      text-indent: 36px;
      color: #666666;
      line-height: 36px;
      margin-bottom: 60px;
    }
    .title {
      margin-bottom: 39px;
    }
    .paragraph {
      width: 100%;
      height: 380px;
      background: #f1f8ff;
      margin-bottom: 60px;
      font-size: 0;
      position: relative;
      .example {
        width: 780px;
        height: 380px;
        position: absolute;
        top: 0;
        right: 0;
      }
      .introduce {
        width: calc(100% - 780px);
        height: 380px;
        background: #f1f8ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        img {
          margin-bottom: 40px;
        }
        p {
          width: 361px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
          text-align: left;
        }
      }
      &:nth-of-type(2n) {
        background: #f6f6f6;
        .example {
          left: 0;
        }
        .introduce {
          left: auto;
          right: 0;
        }
      }
    }
  }
  .navMenu {
    width: 120px;
    height: 900px;
    background: #f1f8ff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.13);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 450px;
    left: 102px;
    z-index: 21;
    .navItem {
      width: 120px;
      height: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .decorate {
        width: 78px;
        height: 1px;
        background: #dcdcdc;
        position: absolute;
        top: -0.5px;
        left: 21px;
      }
      img {
        position: relative;
        top: -15px;
        &:nth-of-type(1) {
          display: block;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
      span {
        width: 100%;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 65px;
        left: 0;
      }
    }
    .activeNavItem {
      background: #0078ff;
      .decorate {
        display: none;
      }
      img {
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
      span {
        color: #ffffff;
      }
    }
  }
}
</style>