import { render, staticRenderFns } from "./5_customer_case.vue?vue&type=template&id=0597d4fb&scoped=true&"
import script from "./5_customer_case.vue?vue&type=script&lang=js&"
export * from "./5_customer_case.vue?vue&type=script&lang=js&"
import style0 from "./5_customer_case.vue?vue&type=style&index=0&id=0597d4fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0597d4fb",
  null
  
)

export default component.exports