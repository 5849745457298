<template>
  <div class="content">
    <div class="carouselDom">
      <!-- 677 210 -->
      <el-carousel
        ref="carousel"
        height="750px"
        autoplay
        indicator-position="none"
        arrow="never"
        class="carousel"
        @change="carouselChange"
      >
        <el-carousel-item :key="0">
          <h3 class="small small_0"></h3>
        </el-carousel-item>
        <el-carousel-item :key="1">
          <h3 class="small small_1"></h3>
        </el-carousel-item>
        <el-carousel-item :key="2">
          <h3 class="small small_2"></h3>
        </el-carousel-item>
        <el-carousel-item :key="3">
          <h3 class="small small_3"></h3>
        </el-carousel-item>
        <el-carousel-item :key="4">
          <h3 class="small small_4"></h3>
        </el-carousel-item>
      </el-carousel>
      <div class="carouselBtnBox">
        <div @click="carouselSwitch(0)">
          <img
            v-if="activeTab != 0"
            src="../../assets/home/轮播选项_1.png"
            alt=""
          />
          <img
            v-if="activeTab == 0"
            src="../../assets/home/轮播选项_2.png"
            alt=""
          />
        </div>

        <div @click="carouselSwitch(1)">
          <img
            v-if="activeTab != 1"
            src="../../assets/home/轮播选项_1.png"
            alt=""
          />
          <img
            v-if="activeTab == 1"
            src="../../assets/home/轮播选项_2.png"
            alt=""
          />
        </div>

        <div @click="carouselSwitch(2)">
          <img
            v-if="activeTab != 2"
            src="../../assets/home/轮播选项_1.png"
            alt=""
          />
          <img
            v-if="activeTab == 2"
            src="../../assets/home/轮播选项_2.png"
            alt=""
          />
        </div>

        <div @click="carouselSwitch(3)">
          <img
            v-if="activeTab != 3"
            src="../../assets/home/轮播选项_1.png"
            alt=""
          />
          <img
            v-if="activeTab == 3"
            src="../../assets/home/轮播选项_2.png"
            alt=""
          />
        </div>

        <div @click="carouselSwitch(4)">
          <img
            v-if="activeTab != 4"
            src="../../assets/home/轮播选项_1.png"
            alt=""
          />
          <img
            v-if="activeTab == 4"
            src="../../assets/home/轮播选项_2.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="application">
      <h2>Application</h2>
      <h3>应用场景</h3>
      <div
        class="carouselDom_1"
        @mouseenter="mouseenterStop()"
        @mouseleave="autoplay()"
      >
        <el-carousel
          ref="carousel_1"
          :autoplay="false"
          indicator-position="none"
          arrow="never"
          direction="vertical"
          class="carousel"
          @change="carousel_1Change"
        >
          <el-carousel-item :key="'small_1_0'">
            <div class="carouselItem">
              <img src="../../assets/home/化学品logo.png" alt="" />
              <h2 v-html="'危险化学品存储管理<br/>危险化学试剂库'"></h2>
              <div
                class="decorate_1"
                :style="{ height: decorateScroll_0.boxHeight + 'px' }"
              >
                <div
                  class="decorate_2"
                  :style="{
                    height: decorateScroll_0.scrollHeight + 'px',
                    top: decorateScroll_0.scrollTop + 'px',
                  }"
                ></div>
              </div>
              <div class="text">
                <div
                  ref="text_0"
                  @mousewheel.stop
                  @scroll.stop="activeDecorateScroll()"
                >
                  高校、科研院所、企事业单位的危险化学品试剂库是存储和保管储存易燃、易爆、有毒、有害物资等危险品的场所，捷隆科技危险化学品智能管理系统结合危险化学品智能控制终端和智能危险化学品储存柜，利用RFID射频识别各类危险化学品的出入库，自动监控管理出入库流程，自动生成出台账，有效避免了各类安全事故的发生。
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item :key="'small_1_1'">
            <div class="carouselItem">
              <img src="../../assets/home/化学品logo.png" alt="" />
              <h2
                v-html="'危险化学品使用管理<br/>实验室危险化学品储存管理'"
              ></h2>
              <div
                class="decorate_1"
                :style="{ height: decorateScroll_1.boxHeight + 'px' }"
              >
                <div
                  class="decorate_2"
                  :style="{
                    height: decorateScroll_1.scrollHeight + 'px',
                    top: decorateScroll_1.scrollTop + 'px',
                  }"
                ></div>
              </div>
              <div class="text">
                <div
                  ref="text_1"
                  @mousewheel.stop
                  @scroll.stop="activeDecorateScroll()"
                >
                  各类实验室的易制毒易制爆剧毒品等危险化学品种类繁多，规格多样，是实验室安全管理工作中的重中之重，通过捷隆科技危险化学品管理系统并结合危险化学品智能控制终端、智能危险化学品储存柜柜，采用智能信息化的管理模式，利用RFID智能识别技术，自动称重记录，有效管控危险化学品使用全流程，杜绝危险化学品使用过程中可能出现的安全隐患，确保人身安全。
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item :key="'small_1_2'">
            <div class="carouselItem">
              <img src="../../assets/home/化学品logo.png" alt="" />
              <h2 v-html="'化学品自动台账管理<br/>库存台账及使用台账'"></h2>
              <div
                class="decorate_1"
                :style="{ height: decorateScroll_2.boxHeight + 'px' }"
              >
                <div
                  class="decorate_2"
                  :style="{
                    height: decorateScroll_2.scrollHeight + 'px',
                    top: decorateScroll_2.scrollTop + 'px',
                  }"
                ></div>
              </div>
              <div class="text">
                <div
                  ref="text_2"
                  @mousewheel.stop
                  @scroll.stop="activeDecorateScroll()"
                >
                  根据国家及《教育部科技司关于开展2019年度高等学校科研实验室安全现场检查的通知》（教技司[2019]178号）文件的通知要求，各单
                  位须完成完善实验室剧毒、易制爆、易制毒危险化学品的存量统计和台账建立工作，建立健全实验室危险化学品台账。捷隆科技智能危险化学品管理系统通过软硬件结合，自动实时记录并生成各类危险化学品的库存台账和使用台账，可以根据时间（年、月、日）、危险化学品种类（易制毒、易制爆、剧毒及精神麻醉、试剂名称等）、使用人等查询导出下载各类报表。
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item :key="'small_1_3'">
            <div class="carouselItem">
              <img src="../../assets/home/化学品logo.png" alt="" />
              <h2 v-html="'实验室安全教育<br/>考核及准入管理'"></h2>
              <div
                class="decorate_1"
                :style="{ height: decorateScroll_3.boxHeight + 'px' }"
              >
                <div
                  class="decorate_2"
                  :style="{
                    height: decorateScroll_3.scrollHeight + 'px',
                    top: decorateScroll_3.scrollTop + 'px',
                  }"
                ></div>
              </div>
              <div class="text">
                <div
                  ref="text_3"
                  @mousewheel.stop
                  @scroll.stop="activeDecorateScroll()"
                >
                  为了提高广大师生员工的安全意识、丰富、安全环保知识、增强防范和应急救援能力、保障师生员工生命财产安全，促进高校健康、快速、稳定的发展，捷隆科技的实验室安全教育、考核及准入管理系统包括安全知识学习、自测考试、在线考试三大功能模块及相关管理功能，共有通识教育类安全常识、化学化工环境类安全常识、电工电器类安全常识、医学生物类安全常识、机械建筑类安全常识、辐射激光类安全常识、网络安全常识、消防安全常识、特种设备安全常识等九个大类安全知识及题库，考试题型分为是非题、单选题、多选三种题型。本系统可根据各单位的实际情况，设置考试相关的内容，调整、修改题库和试题，具有安装方便、操作简单、适用性强，知识涵盖面广等特点，适用于各类实验室安全培训与考试工作。系统包含丰富的安全教育内容，内容分为：安全标识图、MSDS、消防、用电、化学品、生物医药安全、机械制造、土木工程、网络安全、通识等安全知识，以及个人防护、废液、政策法规规章制度等，展示形式为图片、文字、视频、动画等，并包含丰富的应急演练视频。
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div
        class="carouselDom_2"
        @mouseenter="mouseenterStop()"
        @mouseleave="autoplay()"
      >
        <!-- <div class="carousel"> -->
        <el-carousel
          ref="carousel_2"
          height="420px"
          :autoplay="false"
          indicator-position="none"
          arrow="never"
          direction="vertical"
          class="carousel"
        >
          <el-carousel-item :key="'small_0'">
            <h3 class="small small_0"></h3>
          </el-carousel-item>
          <el-carousel-item :key="'small_1'">
            <h3 class="small small_1"></h3>
          </el-carousel-item>
          <el-carousel-item :key="'small_2'">
            <h3 class="small small_2"></h3>
          </el-carousel-item>
          <el-carousel-item :key="'small_3'">
            <h3 class="small small_3"></h3>
          </el-carousel-item>
        </el-carousel>
        <!-- </div> -->
      </div>
      <div class="carouselPage">
        <div :class="{ activeTab_2: activeTab_2 == 0 }">
          <span @click="activeTab_2 = 0">01</span>
          <i></i>
        </div>
        <div :class="{ activeTab_2: activeTab_2 == 1 }">
          <span @click="activeTab_2 = 1">02</span>
          <i></i>
        </div>
        <div :class="{ activeTab_2: activeTab_2 == 2 }">
          <span @click="activeTab_2 = 2">03</span>
          <i></i>
        </div>
        <div :class="{ activeTab_2: activeTab_2 == 3 }">
          <span @click="activeTab_2 = 3">04</span>
          <i></i>
        </div>
      </div>
      <div class="carouselBtn">
        <div
          class="btnL"
          @mousedown="btnL = true"
          @mouseup="
            btnL = false;
            prev();
          "
        >
          <img v-if="!btnL" src="../../assets/home/btnL_1.png" alt="" />
          <img v-if="btnL" src="../../assets/home/btnL_2.png" alt="" />
        </div>
        <div
          class="btnR"
          @mousedown="btnR = true"
          @mouseup="
            btnR = false;
            next();
          "
        >
          <img v-if="!btnR" src="../../assets/home/btnR_1.png" alt="" />
          <img v-if="btnR" src="../../assets/home/btnR_2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="product">
      <div class="sample">
        <img src="../../assets/home/product.png" alt="" />
        <img
          :class="{ activeImg: activeImg == 1 }"
          src="../../assets/home/product_1.png"
          alt=""
        />
        <img
          :class="{ activeImg: activeImg == 2 }"
          src="../../assets/home/product_2.png"
          alt=""
        />
        <img
          :class="{ activeImg: activeImg == 3 }"
          src="../../assets/home/product_3.png"
          alt=""
        />
        <img
          :class="{ activeImg: activeImg == 4 }"
          src="../../assets/home/product_4.png"
          alt=""
        />
      </div>
      <h2>Product</h2>
      <h3>产品中心</h3>
      <span class="icon">♦</span>
      <div class="sampleList">
        <div
          :class="{ activeSample: activeSample == 1 }"
          @mouseenter="activeImg = activeSample = 1"
        >
          <img src="../../assets/home/product_1.png" alt="" />
        </div>
        <div
          :class="{ activeSample: activeSample == 2 }"
          @mouseenter="activeImg = activeSample = 2"
        >
          <img src="../../assets/home/product_2.png" alt="" />
        </div>
        <div
          :class="{ activeSample: activeSample == 3 }"
          @mouseenter="activeImg = activeSample = 3"
        >
          <img src="../../assets/home/product_3.png" alt="" />
        </div>
        <div
          :class="{ activeSample: activeSample == 4 }"
          @mouseenter="activeImg = activeSample = 4"
        >
          <img src="../../assets/home/product_4.png" alt="" />
        </div>
      </div>
      <div class="decorate">
        <div></div>
      </div>
      <h2>
        {{
          activeSample == 1
            ? "智能控制柜"
            : activeSample == 2
            ? "智能危险化学品储存柜"
            : activeSample == 3
            ? "智能净气型化学品储存柜"
            : activeSample == 4
            ? "自动手持盘存机"
            : ""
        }}
      </h2>
      <p>
        {{
          activeSample == 1
            ? "智能控制柜（JELON A）是一款全智能的危险化学品控制管理终端，集成了双人身份认证（人脸+刷卡+指纹）、智能RFID识别技术、自动称重系统，自动进行双人身份认证、自动识别试剂及自动称重上传试剂使用重量；自动查询柜内试剂及库存量，自动语音报警各类违规操作。"
            : activeSample == 2
            ? "智能危险化学品储存柜（JELON A-01/04/06）是一款智能的危险化学品储存柜，柜体采用双层钢板防火设计，柜内配置一次成型PP材质防腐内胆及层板，符合防火防爆规范要求；集成安卓工控一体机，实时显示柜内温湿度、TVOC浓度，门锁及风机状态，并可进行各类违规操作的语音文字报警及报警状态显示上报；同时安装有智能安防网络摄像头，实时监测存储每次打开柜门的影像资料，可远程查看及下载。可通过外接管道，根据柜内TVOC浓度变化，自动控制开启及关闭风机，将挥发性有害气体排出室外。"
            : activeSample == 3
            ? "智能净气型化学品储存柜（JELON B-01/04/06）是一款多功能的智能化学品储存柜，具有智能危险化学品储存柜的全部功能，同时为实验室内部所有用户以及与实验室外部环境相关的所有人员提供安全保证，配置了智能挥发性气体化学吸附过滤净化模块，无需架设管道，不排放化学污染，将酸性、碱性、有机挥发物及有害粉尘粒子吸附净化，实现零排放，为室内、室外环境提供洁净的空气。"
            : activeSample == 4
            ? "自动手持盘存机（JELON C1）采用Arm Cortex-A73架构八核高端CPU，设备运行最新的Android10.0 操作系统,采用全贴合工艺的5.5寸IPS高亮屏，内置9000mAh 超大容量一体化聚合物锂电池，顶级RFID读写性能，支持多标签读取，多标签读取速率为200Tag/s，自主知识产品的控制系统，是试剂仓储盘点的神器。"
            : ""
        }}
      </p>
      <div class="details" @click="tableSwitch(2)">
        <span>查看详情</span>
      </div>
      <img
        @click="tableSwitch(2)"
        src="../../assets/home/更多产品.png"
        alt=""
      />
      <span @click="tableSwitch(2)">更多产品</span>
    </div>
    <div class="coreTechnology">
      <h2>Core Technology</h2>
      <h3>核心技术</h3>
      <span class="icon">♦</span>
      <div>
        <div>
          <img src="../../assets/home/核心技术logo_1.png" alt="" />
          <span>双人认证开锁</span>
          <div v-html="'利用人脸识别、<br/>指纹及刷卡进行身份认证开锁'"></div>
        </div>
        <span>双人认证开锁</span>
      </div>
      <div>
        <div>
          <img src="../../assets/home/核心技术logo_2.png" alt="" />
          <span>RFID识别技术</span>
          <div v-html="'试剂智能识别、智能盘点、自动称重记录'"></div>
        </div>
        <span>RFID识别技术</span>
      </div>
      <div>
        <div>
          <img src="../../assets/home/核心技术logo_3.png" alt="" />
          <span>实时监控</span>
          <div
            v-html="
              '移动监测及报警，<br/> 远程查看视频及 <br/> 下载保存视频资料'
            "
          ></div>
        </div>
        <span>实时监控</span>
      </div>
      <div>
        <div>
          <img src="../../assets/home/核心技术logo_4.png" alt="" />
          <span>化学吸附净化过滤系统</span>
          <div
            v-html="
              '酸性挥发物、碱性挥发物、<br/>有机挥发物（VOC）及粉尘的<br/>实时监测及自动吸附过滤系统'
            "
          ></div>
        </div>
        <span>化学吸附净化过滤系统</span>
      </div>
      <div>
        <div>
          <img src="../../assets/home/核心技术logo_5.png" alt="" />
          <span>智能温度、湿度监测系统</span>
          <div v-html="'实时监测记录智能危化品柜内的温湿度'"></div>
        </div>
        <span>智能温度、湿度监测系统</span>
      </div>
    </div>
    <div class="customerCase">
      <h2>Customer case</h2>
      <h3>典型客户案例</h3>
      <span class="icon">♦</span>
      <span class="more" @click="tableSwitch(2)"> 更多产品 </span>
      <img
        @click="tableSwitch(2)"
        src="../../assets/home/更多产品.png"
        alt=""
      />
      <img
        v-if="activeItem == 1"
        class="logo_1"
        src="../../assets/home/客户logo_1.png"
        alt=""
      />
      <img
        v-if="activeItem == 2"
        class="logo_2"
        src="../../assets/home/客户logo_2.png"
        alt=""
      />
      <img
        v-if="activeItem == 3"
        class="logo_3"
        src="../../assets/home/客户logo_3.png"
        alt=""
      />

      <div class="switchDom">
        <div v-show="activeItem == 1" class="switchItem"></div>
        <div v-show="activeItem == 2" class="switchItem"></div>
        <div v-show="activeItem == 3" class="switchItem"></div>
        <div
          :class="{ activeItemBtn: activeItem == 1 }"
          class="switchItemBtn switchItemBtn_1"
          @mouseenter="activeItem = 1"
        >
          <span>郑州大学</span>
        </div>
        <div
          :class="{ activeItemBtn: activeItem == 2 }"
          class="switchItemBtn switchItemBtn_2"
          @mouseenter="activeItem = 2"
        >
          <span>信阳农林学院</span>
        </div>
        <div
          :class="{ activeItemBtn: activeItem == 3 }"
          class="switchItemBtn switchItemBtn_3"
          @mouseenter="activeItem = 3"
        >
          <span>中原工学院</span>
        </div>
      </div>
    </div>
    <div class="introduce">
      <h2>河南捷隆科技有限公司</h2>
      <h3>
        将物联网、信息技术的新理念和最新实践，应用到实验室安全管理中，让实验室安全管理工作智能化。
      </h3>
      <div class="companyProfile">
        <p>
          河南捷隆科技有限公司位于郑州市中原区，成立于2016年，是一家集研发、销售和技术服务为一体的高科技企业。捷隆科技致力于先进的射频识别技术（RFID）应用、软硬件开发、系统集成，自主研发成功实验室安全智能监控管理系统、智能危险化学品管理系统、智能危险化学品柜、实验室安全知识学习及考核系统、实验室化学挥发气体净化系统等，并完全拥有自主知识产权。公司产品广泛应用于高等院校、科研院所、企事业单位，并可以根据用户实际需求量身定制个性化产品。公司拥有一批学历高、经验丰富的技术团队，同时与高校和科研院所密切高效合作，针对市场需求，持续研发高科技产品。公司具有严格完善的售后服务规程，高效的技术服务团队，以最优质快捷的服务为宗旨，为广大用户提供最为贴心的服务。
        </p>
        <span> 公司简介 </span>
        <div>
          <img src="../../assets/home/logo公司简介.png" alt="" />
          <img src="../../assets/home/简介装饰_角料.png" alt="" />
        </div>
      </div>
      <!--<div class="certificateList">
        <img
          @click="tableSwitch(6)"
          src="../../assets/home/证书_1.png"
          alt=""
        />
        <img
          @click="tableSwitch(6)"
          src="../../assets/home/证书_2.png"
          alt=""
        />
        <img
          @click="tableSwitch(6)"
          src="../../assets/home/证书_3.png"
          alt=""
        />
        <img
          @click="tableSwitch(6)"
          src="../../assets/home/证书_4.png"
          alt=""
        />
        <img
          @click="tableSwitch(6)"
          src="../../assets/home/证书_5.png"
          alt=""
        />
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.autoplay();
    this.$nextTick(() => {
      this.activeDecorateScroll();
    });
    var i = 218;
    var k = 150;
    console.log(i / 1.15, k / (i / 1.15));
  },
  data() {
    return {
      activeTab: 0,
      activeTab_1: 0,
      activeTab_2: 0,
      auto: null,
      activeImg: 1,
      btnL: false,
      btnR: false,
      activeSample: 1,
      activeItem: 1,
      decorateScroll_0: {
        boxHeight: 143,
        scrollHeight: 0,
        scrollTop: 0,
      },
      decorateScroll_1: {
        boxHeight: 143,
        scrollHeight: 0,
        scrollTop: 0,
      },
      decorateScroll_2: {
        boxHeight: 143,
        scrollHeight: 0,
        scrollTop: 0,
      },
      decorateScroll_3: {
        boxHeight: 143,
        scrollHeight: 0,
        scrollTop: 0,
      },
    };
  },
  methods: {
    tableSwitch(v) {
      this.$bus.$emit("tableSwitch", v);
    },
    carouselChange(t, f) {
      this.activeTab = t;
    },
    carouselSwitch(t) {
      this.$refs.carousel.setActiveItem(t);
    },
    prev() {
      if (this.activeTab_2 > 0) {
        this.activeTab_2--;
      } else {
        this.activeTab_2 = 3;
      }
    },
    next() {
      if (this.activeTab_2 < 3) {
        this.activeTab_2++;
      } else {
        this.activeTab_2 = 0;
      }
    },
    autoplay() {
      this.auto ? clearInterval(this.auto) : "";
      this.auto = setInterval(() => {
        this.next();
      }, 3000);
    },
    mouseenterStop() {
      this.auto ? clearInterval(this.auto) : "";
    },
    activeDecorateScroll() {
      var dom = this.$refs["text_" + this.activeTab_2];
      // console.log("滚动条的高度", dom.scrollHeight);
      // console.log("滚动条距离顶部的距离", dom.scrollTop);
      // console.log("滚动条自身的长度", dom.clientHeight);
      this["decorateScroll_" + this.activeTab_2].scrollHeight =
        this["decorateScroll_" + this.activeTab_2].boxHeight *
        (dom.clientHeight / dom.scrollHeight);
      this["decorateScroll_" + this.activeTab_2].scrollTop =
        this["decorateScroll_" + this.activeTab_2].boxHeight *
        (dom.scrollTop / dom.scrollHeight);
    },
    carousel_1Change() {
      this.autoplay();
    },
  },
  watch: {
    activeTab_2() {
      this.activeTab_1 = this.activeTab_2;
      this.$refs.carousel_1.setActiveItem(this.activeTab_1);
      this.$refs.carousel_2.setActiveItem(this.activeTab_2);
      var dom = this.$refs["text_" + this.activeTab_2];
      dom.scrollTop = 0;
      this.$nextTick(() => {
        this.activeDecorateScroll();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  .carouselDom {
    width: 100%;
    height: 750px;
    position: relative;
    ::v-deep .carousel {
      .small {
        height: 100%;
        position: relative;
      }
      .small_0 {
        background: url("../../assets/home/banner_0.jpg") 0 0/100% 100%
          no-repeat;
      }
      .small_1 {
        background: url("../../assets/home/banner_1.jpg") 0 0/100% 100%
          no-repeat;
      }
      .small_2 {
        background: url("../../assets/home/banner_2.jpg") 0 0/100% 100%
          no-repeat;
      }
      .small_3 {
        background: url("../../assets/home/banner_3.jpg") 0 0/100% 100%
          no-repeat;
      }
      .small_4 {
        background: url("../../assets/home/banner_4.jpg") 0 0/100% 100%
          no-repeat;
      }
    }
    .carouselBtnBox {
      width: calc(100% - 210px);
      height: 16px;
      display: flex;
      position: absolute;
      top: 677px;
      left: 210px;
      z-index: 10;
      div {
        width: 16px;
        height: 16px;
        margin-right: 14px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .application {
    width: 100%;
    height: 599px;
    position: relative;
    h2:nth-of-type(1) {
      height: 39px;
      font-size: 42px;
      font-family: Arial;
      font-weight: bold;
      color: #333333;
      line-height: 39px;
      position: absolute;
      top: 71px;
      left: 359px;
    }
    > h3 {
      height: 23px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      position: absolute;
      top: 125px;
      left: 360px;
    }

    .carouselDom_1 {
      width: 590px;
      height: 300px;
      position: absolute;
      top: 170px;
      left: 359px;
      .carousel {
        width: 100%;
        height: 100%;
      }
      .carouselItem {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          width: 32px;
          height: 37px;
          position: absolute;
          top: 38px;
          left: 0px;
        }
        h2 {
          height: 65px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #000000;
          line-height: calc(65px / 2);
          position: absolute;
          top: 25px;
          left: 46px;
        }
        .decorate_1 {
          width: 4px;
          // height: 143px;
          background: #c7d1dc;
          position: absolute;
          top: 125px;
          left: 2px;
          .decorate_2 {
            width: 4px;
            // height: 72px;
            background: #0078ff;
            position: absolute;
            left: 0;
          }
        }
        .text {
          width: 515px;
          height: 180px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
          overflow: hidden;
          position: absolute;
          top: 120px;
          left: 34px;
          div {
            width: 99%;
            height: 100%;
            padding-right: 25px;
            padding-bottom: 17px;
            overflow: auto;
          }
        }
      }
    }
    .carouselDom_2 {
      width: 550px;
      height: 420px;
      position: absolute;
      top: 89px;
      left: 955px;
      z-index: 1;
      ::v-deep .carousel {
        width: 100%;
        height: 420px;
        .small_0 {
          height: 100%;
          background: url("../../assets/home/中层轮播1.png") 0 0/100% 100%
            no-repeat;
        }
        .small_1 {
          height: 100%;
          background: url("../../assets/home/中层轮播2.png") 0 0/100% 100%
            no-repeat;
        }
        .small_2 {
          height: 100%;
          background: url("../../assets/home/中层轮播3.jpg") 0 0/100% 100%
            no-repeat;
        }
        .small_3 {
          height: 100%;
          background: url("../../assets/home/中层轮播4.jpg") 0 0/100% 100%
            no-repeat;
        }
      }
    }
    .carouselPage {
      width: 26px;
      height: 325px;
      position: absolute;
      top: 115px;
      right: 360px;
      div {
        margin: 83px 0;
        position: relative;
        span {
          height: 19px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          line-height: 19px;
          cursor: pointer;
        }
        i {
          display: none;
          width: 92px;
          height: 2px;
          background: #0078ff;
          position: absolute;
          top: 41px;
          right: 0px;
          z-index: 2;
        }
      }
      .activeTab_2 {
        span {
          color: #0078ff;
        }
        i {
          display: block;
        }
      }

      div:nth-of-type(1) {
        margin-top: 0;
      }
      div:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
    .carouselBtn {
      width: 102px;
      height: 38px;
      position: absolute;
      top: 471px;
      left: 893px;
      div,
      img {
        width: 40px;
        height: 38px;
        font-size: 0;
        z-index: 2;
        cursor: pointer;
      }
      .btnL {
        position: absolute;
        top: 0;
        left: 0;
      }
      .btnR {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .product {
    width: 100%;
    height: 750px;
    position: relative;
    .sample {
      width: 404px;
      height: 681px;
      background: #ff722b;
      position: absolute;
      top: 0;
      left: 359px;
      img {
        display: none;
        position: absolute;
      }
      img:nth-of-type(1) {
        display: block;
        bottom: 0;
        left: 0;
      }
      img:nth-of-type(2) {
        top: 186px;
        left: 102px;
      }
      img:nth-of-type(3) {
        top: 62px;
        left: 166px;
      }
      img:nth-of-type(4) {
        top: 62px;
        left: 166px;
      }
      img:nth-of-type(5) {
        top: 212px;
        left: 81px;
      }
      .activeImg {
        display: block;
      }
    }
    h2:nth-of-type(1) {
      height: 32px;
      font-size: 42px;
      font-family: Arial;
      font-weight: bold;
      color: #333333;
      line-height: 32px;
      position: absolute;
      top: 47px;
      left: 888px;
    }
    h3:nth-of-type(1) {
      height: 23px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      position: absolute;
      top: 90px;
      left: 888px;
    }
    .icon {
      width: 13px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #0078ff;
      line-height: 18px;
      position: absolute;
      top: 94px;
      left: 1004px;
    }
    .sampleList {
      width: 670px;
      // height: 140px;
      font-size: 0;
      position: absolute;
      top: 180px;
      left: 888px;
      div {
        width: 120px;
        height: 140px;
        background: #ffffff;
        border: 2px solid #ffffff;
        box-shadow: none;
        display: inline-block;
        margin-right: 30px;
        position: relative;
        cursor: pointer;
        img {
          position: absolute;
        }
        &:nth-of-type(1) {
          img {
            width: 84px;
            height: 87px;
            top: calc((140px - 87px) / 2);
            left: calc((120px - 84px) / 2);
          }
        }
        &:nth-of-type(2) {
          img {
            width: 66px;
            height: 119px;
            top: calc((140px - 119px) / 2);
            left: calc((120px - 66px) / 2);
          }
        }
        &:nth-of-type(3) {
          img {
            width: 66px;
            height: 119px;
            top: calc((140px - 119px) / 2);
            left: calc((120px - 66px) / 2);
          }
        }
        &:nth-of-type(4) {
          img {
            width: 103px;
            height: 88px;
            top: calc((140px - 88px) / 2);
            left: calc((120px - 103px) / 2);
          }
        }
      }
      .activeSample {
        border: 2px solid #0078ff;
        box-shadow: 0px 0px 6px 0px rgba(0, 120, 255, 0.69);
      }
    }
    .decorate {
      width: 670px;
      height: 1px;
      background: #c7d1dc;
      position: absolute;
      top: 383px;
      left: 888px;
      div {
        width: 168px;
        height: 4px;
        background: #0078ff;
        position: absolute;
        top: calc((1px - 4px) / 2);
      }
    }
    h2:nth-of-type(2) {
      height: 29px;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 29px;
      position: absolute;
      top: 417px;
      left: 888px;
    }
    p {
      width: 670px;
      // height: 46px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
      position: absolute;
      top: 460px;
      left: 888px;
    }
    .details {
      width: 150px;
      height: 40px;
      background: linear-gradient(90deg, #0078ff, #0090ff);
      border-radius: 20px;
      text-align: center;
      cursor: pointer;
      position: absolute;
      top: 645px;
      left: 888px;
      span {
        height: 16px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #ffffff;
        line-height: 40px;
      }
    }
    > img {
      width: 33px;
      height: 32px;
      position: absolute;
      top: 622px;
      left: 1512px;
      cursor: pointer;
    }
    > span {
      height: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
      position: absolute;
      top: 671px;
      left: 1495px;
      cursor: pointer;
    }
  }
  .coreTechnology {
    width: 100%;
    height: 951px;
    position: relative;
    h2 {
      &:nth-of-type(1) {
        height: 40px;
        font-size: 42px;
        font-family: Arial;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
        position: absolute;
        top: 39px;
        left: 365px;
      }
    }
    h3 {
      &:nth-of-type(1) {
        height: 23px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 23px;
        position: absolute;
        top: 91px;
        left: 365px;
      }
    }
    .icon {
      width: 13px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #0078ff;
      line-height: 18px;
      position: absolute;
      top: 93.5px;
      left: 475px;
    }
    > div {
      text-align: center;
      cursor: pointer;
      position: absolute;
      > div {
        opacity: 0;
        img {
          position: absolute;
        }
        span {
          width: 100%;
          height: 24px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
          position: absolute;
          left: 0;
        }
        div {
          width: 100%;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
          position: absolute;
          left: 0;
        }
      }
      &:nth-of-type(1) {
        width: 400px;
        height: 680px;
        top: 182px;
        left: 361px;
        background: url("../../assets/home/核心技术_1.png") 0 0/100% 100%
          no-repeat;
        > div {
          width: 320px;
          height: 600px;
          background: #ff722b;
          position: absolute;
          top: 36px;
          left: 40px;
          img {
            top: 205px;
            left: 136px;
          }
          span {
            top: 291px;
          }
          div {
            top: 347px;
          }
        }
      }
      &:nth-of-type(2) {
        width: 420px;
        height: 330px;
        top: 182px;
        left: 780px;
        background: url("../../assets/home/核心技术_2.png") 0 0/100% 100%
          no-repeat;
        > div {
          width: 355px;
          height: 250px;
          background: #ff722b;
          position: absolute;
          top: 36px;
          left: 34px;
          img {
            top: 47px;
            left: 146px;
          }
          span {
            top: 135px;
          }
          div {
            top: 181px;
          }
        }
      }
      &:nth-of-type(3) {
        width: 340px;
        height: 512px;
        top: 0;
        left: 1220px;
        background: url("../../assets/home/核心技术_3.png") 0 0/100% 100%
          no-repeat;
        > div {
          width: 260px;
          height: 432px;
          background: #ff722b;
          position: absolute;
          top: 38px;
          left: 40px;
          img {
            top: 110px;
            left: 106px;
          }
          span {
            top: 196px;
          }
          div {
            top: 242px;
          }
        }
      }
      &:nth-of-type(4) {
        width: 340px;
        height: 330px;
        top: 532px;
        left: 780px;
        background: url("../../assets/home/核心技术_4.png") 0 0/100% 100%
          no-repeat;
        > div {
          width: 260px;
          height: 260px;
          background: #ff722b;
          position: absolute;
          top: 36px;
          left: 41px;
          img {
            top: 28px;
            left: 95px;
          }
          span {
            top: 110px;
          }
          div {
            top: 156px;
          }
        }
      }
      &:nth-of-type(5) {
        width: 420px;
        height: 330px;
        top: 532px;
        left: 1138px;
        background: url("../../assets/home/核心技术_5.png") 0 0/100% 100%
          no-repeat;
        > div {
          width: 355px;
          height: 250px;
          background: #ff722b;
          position: absolute;
          top: 36px;
          left: 34px;
          img {
            top: 31px;
            left: 154px;
          }
          span {
            top: 120px;
          }
          div {
            top: 186px;
          }
        }
      }
      > span {
        width: 100%;
        height: 24px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        position: absolute;
        bottom: 10px;
        left: 0;
      }
      @keyframes identifier {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      &:hover {
        > div {
          animation: identifier 0.5s linear 1 forwards;
        }
        > span {
          animation: identifier 0.5s linear 1 reverse forwards;
        }
      }
    }
  }
  .customerCase {
    width: 100%;
    height: 604px;
    position: relative;
    @function getT($v) {
      @return ($v - 89px);
    }
    h2 {
      &:nth-of-type(1) {
        height: 32px;
        font-size: 42px;
        font-family: Arial;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        position: absolute;
        top: 0;
        left: 360px;
      }
    }
    h3 {
      &:nth-of-type(1) {
        height: 23px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 23px;
        position: absolute;
        top: getT(135px);
        left: 360px;
      }
    }
    .icon {
      width: 13px;
      height: 17px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #0078ff;
      line-height: 17px;
      position: absolute;
      top: getT(139px);
      left: 529px;
    }
    .more {
      width: 65px;
      height: 16px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      cursor: pointer;
      position: absolute;
      top: getT(105px);
      left: 1452px;
    }
    > img {
      &:nth-of-type(1) {
        width: 32px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        top: getT(96px);
        left: 1530px;
      }
      &:nth-of-type(n + 2) {
        position: absolute;
      }
    }
    .logo_1 {
      width: 160px;
      height: 160px;
      top: 116px;
      left: 393px;
    }
    .logo_2 {
      width: 161px;
      height: 163px;
      top: 116px;
      left: 393px;
    }
    .logo_3 {
      width: 160px;
      height: 160px;
      top: 116px;
      left: 393px;
    }
    .switchDom {
      width: 979px;
      height: 420px;
      font-size: 0;
      position: absolute;
      top: getT(186px);
      left: 579px;
      .switchItem {
        width: 979px;
        height: 420px;
        &:nth-of-type(1) {
          background: url("../../assets/home/客户示例_1.png") 0 0/100% 100%
            no-repeat;
        }
        &:nth-of-type(2) {
          background: url("../../assets/home/客户示例_2.png") 0 0/100% 100%
            no-repeat;
        }
        &:nth-of-type(3) {
          background: url("../../assets/home/客户示例_3.png") 0 0/100% 100%
            no-repeat;
        }
      }
      .switchItemBtn {
        width: 178px;
        height: 50px;
        background: #f2f8ff;
        border-radius: 25px 0px 0px 25px;
        position: absolute;
        right: 100%;
        cursor: pointer;
        span {
          width: 108px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #1b3a5d;
          line-height: 50px;
          text-align: center;
          position: absolute;
          top: 0;
          right: 28px;
        }
      }
      .switchItemBtn_1 {
        top: 222px;
      }
      .switchItemBtn_2 {
        top: 296px;
      }
      .switchItemBtn_3 {
        top: 370px;
      }
      .activeItemBtn {
        width: 217px;
        height: 50px;
        background: linear-gradient(0deg, #2879ff 0%, #03a1f8 100%);
        border-radius: 25px 0px 0px 25px;
        span {
          color: #ffffff;
        }
      }
    }
  }
  .introduce {
    width: 100%;
    height: 550px;
    background: #f2f8ff;
    position: relative;
    h2 {
      &:nth-of-type(1) {
        height: 32px;
        font-size: 34px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #0078ff;
        line-height: 32px;
        position: absolute;
        top: 92px;
        left: 363px;
      }
    }
    h3 {
      &:nth-of-type(1) {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        position: absolute;
        top: 148px;
        left: 363px;
      }
    }
    .companyProfile {
      width: 1195px;
      height: 270px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(2, 11, 53, 0.16);
      position: absolute;
      top: 205px;
      left: 363px;
      background: url("../../assets/home/公司简介背景.png") 0 0/100% 100%
        no-repeat;
      p {
        width: 643px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        position: absolute;
        top: 30px;
        left: 102px;
      }
      span {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 16px;
        position: absolute;
        top: 232px;
        left: 698px;
      }
      > div {
        width: 330px;
        height: 383px;
        background: url("../../assets/home/简介装饰.png");
        position: absolute;
        bottom: 0;
        right: 75px;
        img {
          &:nth-of-type(1) {
            width: 262px;
            height: 41px;
            position: absolute;
            top: 172px;
            left: 36px;
          }
          &:nth-of-type(2) {
            width: 66px;
            height: 113px;
            position: absolute;
            top: 0;
            left: 100%;
          }
        }
      }
    }
    .certificateList {
      width: 1195px;
      height: 310px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 494px;
      left: 363px;
      img {
        width: 213px;
        height: 310px;
        cursor: pointer;
      }
    }
  }
}
</style>
