<template>
  <div class="erviceSupport">
    <div class="background">
      <h2>服务支持</h2>
    </div>
    <div class="summary">
      <img
        class="icon"
        src="../../assets/service_support/标题icon.png"
        alt=""
      />
      <h2>服务宗旨</h2>
      <span
        >公司具有严格完善的售后服务规程，高效的技术服务团队，以最优质快捷的服务为宗旨，为广大用户提供最为贴心的服务。</span
      >
    </div>
    <div class="tabBox">
      <div class="tabItem">
        <div class="content">
          <img src="../../assets/service_support/示例图_1.png" alt="" />
          <h2>化学烧伤的急救方法</h2>
          <span>2021-08-28</span>
          <p>
            化学烧伤比单纯的热力烧伤更为复杂，由于化学物品本身的特性，化学物质对人体组织有热力、腐蚀致伤作用，造成对组织的损伤同，其烧伤程度取决于化学物质的种类、浓度和作用持续时间，所以在急救处理上有其特点。
          </p>
        </div>
        <div class="collapseBox">
          <el-collapse
            class="collapse"
            @change="openCollapse_1 = !openCollapse_1"
          >
            <el-collapse-item>
              <template slot="title">
                <div class="collapseTitle">
                  <h2>具体说明</h2>
                  <div>
                    <span>{{ openCollapse_1 ? "收起" : "展开" }}</span>
                    <img
                      v-if="!openCollapse_1"
                      src="../../assets/service_support/展开符_1.png"
                      alt=""
                    />
                    <img
                      v-if="openCollapse_1"
                      src="../../assets/service_support/展开符_2.png"
                      alt=""
                    />
                  </div>
                </div>
              </template>
              <!-- <p
                class="collapseContent"
                v-html="
                  `Q:如何进行重量管理? <br/> A:重量管理通过智能化学管理终端进行，终端带重登记功能，化学品管理环节中与重量相关的环节(入库,用量记录,报废) , 均在该终端进行。<br/> Q:自动登记重量的精度是多少? <br/> A:智能化学管理终端的称重精度为0.1g,满足大部分管制类危化品的登记需求;若使用量小于0.1g,可以使用手动登记用量。<br/> Q:手动登记用量和自动登记有什么区别? <br/> A:手动登记需要在电脑端或微信端进行操作，自动登记只需在智能化学管理终端感应即可。手动和自动登记都会改变化学品的剩余剩余重量。<br/> Q:自动感应的重量与实际情况不符怎么办? <br/> A:可以通过手动登记进行修正,修正后的数据作为注释存在，并保留登记人的信息;可以多次修改。`
                "
              ></p> -->
              <pre class="collapseContent">
1、高温矿渣烧伤处理援救方案 
    （1）立即将伤员救出烧伤现场。 
    （2）迅速熄灭被烧着的衣服鞋帽，并脱掉烧坏的衣物。 
    （3）立即用大量自来水冲洗创面3-5分钟，入口内和鼻腔内进入火灰，要立即漱口和清理，如眼内有矿灰要用植物油或石蜡油棉签蘸去颗粒。 
    （4）视伤情需送医院治疗的，要立即由专人护送，用干净的布覆盖创面，以防途中发生意外。 
2、强酸类
    强酸类如盐酸、硫酸、硝酸、王水(盐酸和硝酸)。石炭酸等，伤及皮肤时，因其浓度、液量、面积等因素不同而造成轻重不同的伤害。酸与皮肤接触，立即 引起组织蛋白的凝固使组织脱水，形成厚痂。厚痂的形成可以防止酸液继续向深层组织浸透，减少损害,对伤员健康极为有利。如现场处理及时，一般不会造成深度 烧伤。更重要的是注意眼睛，盐酸、石炭酸的烧伤，创面呈白色或灰黄色；硫酸的创面呈棕褐色；碳酸的创面呈黄色。
    如系通过衣服浸透烧伤，应即刻脱去，并迅速用大量清水反复地冲洗伤面。充分冲洗后也可用中和剂--弱碱性液体如小苏打水(碳酸氢钠)、肥皂水冲洗。石炭酸烧伤用酒精中和。硝酸烧伤用攸琐溶液中和，效果更好。但若无中和剂也不必强求，因为充分的清水冲洗是最根本的措施。
硫酸烧伤的现场处理预案 
    （1）立即将伤员脱离出事点，要尽快把酸除去。 
    （2）一般烧伤的紧急处理：首先用大量水流连续冲洗，把冲洗下沾有硫酸的衣鞋等迅速脱掉，直冲洗到硫酸痕迹消失为止，不论哪个部位，都只能用大量水冲洗，不能用弱碱性溶液之类的东西来中和硫酸，防止进一步烧伤。如烧伤过重，范围大时，可能引起脉搏加速盗汗、虚脱之类的危急症，这时患者必须仰卧（背朝下躺 着）全身保温，防止出现其他病症，并迅速送往医院救治。 
    （3）硫酸溅到眼睛内的处理，不管溅入眼内的硫酸浓度如何和硫酸量的多少，必须用大量流水（没有压力），眼皮撑开或眼皮翻开的情况下连续冲洗15分钟，要把眼皮和眼球的所有地方全部用水仔细冲洗，冲洗后立即送医院。 
    （4）吸入硫酸蒸气时的处理：当吸入大量的发烟硫酸或高温硫酸所产生的酸雾或蒸汽时，要立即离开污染现场。如已昏迷和发生呼吸困难时，既要立即使其仰卧，并迅速送往医院急救。 
    （5）喝下硫酸时的处理方法：即使喝下很稀的硫酸也会引起口、咽喉、食道和胃的烧伤。如喝下稀硫酸，可设法使其呕吐，吐出后再多喝水慢慢缓解之。如喝下浓硫酸，切勿使患者吐出应立即使大量清水漱口，让其多喝水待喝饱后设法使其吐出，然后再多喝水，要尽快送往医院治疗。
3、强碱类
    强碱类如苛性碱(氢氧化钾、氢氧化钠)、石灰等。强碱对组织的破坏力比强酸为重，因其渗透性较强，深入组织使细胞脱水，溶解组织蛋白，形成强碱蛋白化合物而使伤面加深。
如果碱性溶液浸透衣服造成的烧伤，应立即脱去受污染衣服，并用大量清水彻底冲洗伤处。
    充分清洗后，可用稀盐酸、稀醋酸(或食醋)中和剂。再用碳酸氢纳溶液或碱性肥皂水中和。根据情况，请医生采用其他措施处理。
4、磷
    磷烧伤，在工农业生产中常能见到，在战时磷弹爆炸也常造成烧伤。磷及磷的化合物在空气中极易燃烧，氧化成五氧化二磷。伤面在白天能冒烟。夜晚可有磷光。这是磷在皮肤上继续燃烧之故。因此伤面多较深，而且磷是一种毒性很强的物质，被身体吸收后，能引起全身性中毒。
    急救处理的原则是灭火除磷、然后用有关液体包扎。如磷仍在皮肤上燃烧，应迅速灭火，用大量清水冲洗。冲洗后，再仔细察看局部有无残留磷质，也可在暗 处观察，如有发光处，用小镊子夹剔除去，然后用浸透l%的硫酸铜纱布敷盖局部，以使残留磷生成黑色的二磷化三铜，然后再冲去。也可以用3%双氧水或5%碳 酸氢纳溶液冲洗，使磷氧化为磷酐。如无上述药液，可用大量清水冲洗局部。
    一般烧伤多用油纱布局部包扎，但在磷伤时应禁用。因磷易溶于油类，促使机体吸收而造成全身中毒，而改用2.5%碳酸氢钠溶液陋敷两小时后，再用干纱布包扎。
    对于全身中毒者，主要是采取保护肝脏的疗法，如静脉注射50%高渗葡萄糖液，或静脉点滴5一10%的葡萄糖液，加入大量的维生素C。服用其他保肝药物如肝泰乐。肾脏损伤出现蛋白尿、血尿者，可应用碱性药物如碳酸氢钠注射，卧床休息。
              </pre>
            </el-collapse-item>
          </el-collapse>
          <div class="decorate"></div>
        </div>
      </div>

      <div class="tabItem">
        <div class="content">
          <img src="../../assets/service_support/示例图_1.png" alt="" />
          <h2>危险化学品的腐蚀性?</h2>
          <span>2021-08-28</span>
          <p>
            凡能腐蚀人体、金属和其他物质的物质，称为腐蚀性物质。按腐蚀性的强弱，腐蚀性物质可分为两级，按其酸碱性及有机物、无机物则可分为八类。
          </p>
        </div>
        <div class="collapseBox">
          <el-collapse
            class="collapse"
            @change="openCollapse_2 = !openCollapse_2"
          >
            <el-collapse-item>
              <template slot="title">
                <div class="collapseTitle">
                  <h2>具体说明</h2>
                  <div>
                    <span>{{ openCollapse_2 ? "收起" : "展开" }}</span>
                    <img
                      v-if="!openCollapse_2"
                      src="../../assets/service_support/展开符_1.png"
                      alt=""
                    />
                    <img
                      v-if="openCollapse_2"
                      src="../../assets/service_support/展开符_2.png"
                      alt=""
                    />
                  </div>
                </div>
              </template>
              <!-- <p
                class="collapseContent"
                v-html="
                  `Q:如何进行重量管理? <br/> A:重量管理通过智能化学管理终端进行，终端带重登记功能，化学品管理环节中与重量相关的环节(入库,用量记录,报废) , 均在该终端进行。<br/> Q:自动登记重量的精度是多少? <br/> A:智能化学管理终端的称重精度为0.1g,满足大部分管制类危化品的登记需求;若使用量小于0.1g,可以使用手动登记用量。<br/> Q:手动登记用量和自动登记有什么区别? <br/> A:手动登记需要在电脑端或微信端进行操作，自动登记只需在智能化学管理终端感应即可。手动和自动登记都会改变化学品的剩余剩余重量。<br/> Q:自动感应的重量与实际情况不符怎么办? <br/> A:可以通过手动登记进行修正,修正后的数据作为注释存在，并保留登记人的信息;可以多次修改。`
                "
              ></p> -->
              <pre class="collapseContent">
（一）化学腐蚀性物质的分类
1．一级无机酸性腐蚀物质。这类物质具有强腐蚀性和酸性。主要是一些具有氧化性的强酸，如氢氟酸、硝酸、硫酸、氯磺酸等。还有遇水能生成强酸的物质，如二氧化氮、二氧化硫、三氧化硫、五氧化二磷等。
2．一级有机酸性腐蚀物质。具有强腐蚀性及酸性的有机物，如甲酸、氯乙酸、磺酸酰氯、乙酰氯、苯甲酰氯等。
3．二级无机酸性腐蚀物质。这类物质主要是氧化性较差的强酸，如烟酸、亚硫酸，亚硫酸氢铵，磷酸等，以及与水接触能部分生成酸的物质，如四氧化碲。
4．二级有机酸性腐蚀物质。主要是一些较弱的有机酸，如乙酸、乙酸酐、丙酸酐等。
5．无机碱性腐蚀物质。具有强碱性无机腐蚀物质，如氢氧化钠、氯氧化钾，以及与水作用能生成碱性的腐蚀物质，如氧化钙、硫化钠等。
6．有机碱性腐蚀物质。具有碱性的有机腐蚀物质，主要是有机碱金属化合物和胺类，如二乙醇胺、甲胺、甲醇钠。
7．其他无机腐蚀物质。这类物质有漂白粉、三氯化碘、溴化硼等。
8．其他有机腐蚀物质。如甲醛、苯酚、氯乙醛、苯酚钠等。
（二）化学腐蚀性物质的特性
1．强烈的腐蚀性
    这种性质是腐蚀性物质的共性。它对人体、设备、建筑物、构筑物、车辆船舶的金属结构都有很大的腐蚀和破坏作用。
2．氧化性
    腐蚀性物质如硝酸，浓硫酸、氯磺酸、过氧化氢、漂白粉等，都是氧化性很强的物质，与还原物或有机物接触时会发生强烈的氧化-还原反应，放出大量的热，容易引起燃烧。
3．遇水发热性
    多种腐蚀性物质遇水会放出大量的热，造成液体四处飞溅，致使人体灼伤。
4．毒害性
    许多腐蚀性物质不但本身毒性大，而且会产生有毒蒸汽，如SO2、HF等。
    腐蚀性物质接触人的皮肤、眼睛或进入肺部、食道等会对表皮细胞组织产生破坏作用而造成灼伤，灼伤后常引起炎症，甚至造成死亡。固体腐蚀性物质一般直接灼伤表皮，而液体或气体状态的腐蚀性物质会很快进入人体内部器官，如氢氟酸、烟酸、四氧化二氮等。
5．燃烧性
    许多有机腐蚀性物质不仅本身可燃，而且能挥发出易燃蒸气。
              </pre>
            </el-collapse-item>
          </el-collapse>
          <div class="decorate"></div>
        </div>
      </div>

      <div class="tabItem">
        <div class="content">
          <img src="../../assets/service_support/示例图_1.png" alt="" />
          <h2>危险化学品毒性、腐蚀性的防护</h2>
          <span>2021-08-28</span>
          <p>
            毒害性、腐蚀性是危险化学品的又一重要危险特性。绝大部分危险化学品均具有毒害性。例如，氯酸钾既是氧化剂，又是剧毒物品；一氧化碳在GB13690中被列为易燃气体，同时又具有毒性；甲酸、氢氟酸既是腐蚀品，同时又有毒，也属于毒害品。
          </p>
        </div>
        <div class="collapseBox">
          <el-collapse
            class="collapse"
            @change="openCollapse_3 = !openCollapse_3"
          >
            <el-collapse-item>
              <template slot="title">
                <div class="collapseTitle">
                  <h2>具体说明</h2>
                  <div>
                    <span>{{ openCollapse_3 ? "收起" : "展开" }}</span>
                    <img
                      v-if="!openCollapse_3"
                      src="../../assets/service_support/展开符_1.png"
                      alt=""
                    />
                    <img
                      v-if="openCollapse_3"
                      src="../../assets/service_support/展开符_2.png"
                      alt=""
                    />
                  </div>
                </div>
              </template>
              <!-- <p
                class="collapseContent"
                v-html="
                  `Q:如何进行重量管理? <br/> A:重量管理通过智能化学管理终端进行，终端带重登记功能，化学品管理环节中与重量相关的环节(入库,用量记录,报废) , 均在该终端进行。<br/> Q:自动登记重量的精度是多少? <br/> A:智能化学管理终端的称重精度为0.1g,满足大部分管制类危化品的登记需求;若使用量小于0.1g,可以使用手动登记用量。<br/> Q:手动登记用量和自动登记有什么区别? <br/> A:手动登记需要在电脑端或微信端进行操作，自动登记只需在智能化学管理终端感应即可。手动和自动登记都会改变化学品的剩余剩余重量。<br/> Q:自动感应的重量与实际情况不符怎么办? <br/> A:可以通过手动登记进行修正,修正后的数据作为注释存在，并保留登记人的信息;可以多次修改。`
                "
              ></p> -->
              <pre class="collapseContent">
1．毒物的形态
    在一般条件下，毒物常以一定的物理形态即固体、液体或气体的形式存在，但在危险化学品的生产、使用、储存等过程中，还可以呈现为粉尘、烟尘、雾、蒸气等形态。有毒化学品在水中的溶解度越大，其危险性越大  。
2．毒物的作用条件
    危险化学品的毒性大小或作用特点，与其化学结构、理化性质、剂量(或浓度)、环境条件以及个体敏感性等一系列因素有关。一般米说，空气中毒物的浓度愈高、接触时问过长、防护不严，就越容易造成中毒。
（二）防毒、防腐蚀措施
1．加强化学毒性防护教育与管理
    （1）全面了解毒物的性质，有针对性地采取防治手段。要预防化学中毒，首先必须掌握在实验、生产过程中存在的毒物的种类、物质、来源、泄漏及散发的条件然后选择防护手段。
    （2）健全组织，加强管理，严格执行规章制度和安全操作规程。违章操作、违章检修、设备缺陷或维护不当、不重视防护是发生化学中毒，尤其是急性中毒的重要原因。
    （3）加强宣传教育，普及防毒知识提高自救能力。通过宣传教育，提高师生对化学安全工作重要性的认识，了解防治常识，提高自救互救能力。
2．防毒措施
    （1）改革工艺或实验路线，消除或改造毒源。在选择工艺路线时，尽量以无毒、低毒物质代替有毒、高毒物质进行实验、生产。自动化、密闭化、管道化、连续化的实验、生产过程可以减少人与毒物的接触机会和毒物泄漏现象。
    （2）保持空气新鲜。通风排毒措施可分为两大类，即自然通风和机械通风。 一般要求是保证实验、生产场所有良好的气象条件和足够的换气量。环境中的有害物质浓度不得超过最高容许浓度。
正确使用通风柜、换气扇等设施，防止进风口与出风口短路。另外，对于刚装修好的房间或空调房间，一定要经常或定时换气，防止有毒气体的浓度上升，危害身体。
    （3）采取个人防护措施。在其他技术措施不能从根本上防毒时，必须采取个人防护措施。其作用是隔离和屏敝（如防护服、口罩、鞋帽、防护面罩、防护手 套、防音器等）及吸收过滤（如防护眼镜、呼吸防护器等）有毒物质。选用合适的防护用品，可以减轻受毒物影响的程度，起到一定的保护作用。
    养成良好的卫生习惯也是消除和降低化学品毒害的自救方法。保持个人卫生，就可以防止有毒化学品附着在皮肤上，防止有害物质通过皮肤、口腔、消化道侵入人体。例如，禁止在有毒作业场所吃饭、饮水、吸烟，饭前洗手漱口，勤洗澡，定期清洗工作服等。
3．防腐蚀措施
    （1）存放腐蚀性物品时应避开易被腐蚀的物品，注意其容器的密封性，并保持实验室内部的通风。
    （2）产生腐蚀性挥发气体的实验室，应有良好的局部通风或全室通风，且远离有精密仪器设备的实验室。应将使用腐蚀性物品的实验室设在高层，以使腐蚀性挥发气体向上扩散。
    （3）装有腐蚀性物品的容器必须采用耐腐蚀的材料制作。例如，不能用铁质容器存放酸液，不能用玻璃器皿存放浓碱液等。使用腐蚀性物品时，要仔细小心，严格按照操作规程，在通风柜内操作。
    （4）酸、碱废液，不能直接倒入下水道，应经过处理达到安全标准后才能排放。应经常检查，定期维修更换腐蚀性气体、液体流经的管道、阀门。
    （5）搬运、使用腐蚀性物品要穿戴好个人防护用品。若不慎将酸或碱溅到皮肤或衣服上，可用大量水冲洗。
    （6）对散布有酸、碱气体的房间内的易被腐蚀器材，要设置专门防腐罩或采取其他防护措施，以保证器材不被侵蚀。
              </pre>
            </el-collapse-item>
          </el-collapse>
          <div class="decorate"></div>
        </div>
      </div>

      <div class="tabItem">
        <div class="content">
          <img src="../../assets/service_support/示例图_1.png" alt="" />
          <h2>剧毒品的管理</h2>
          <span>2021-08-28</span>
          <p>
            实验室是学校开展人才培养和科学研究的必备场所，实验室的安全管理，尤其是剧毒品的安全管理对于整个高校的安全和稳定至关重要。许多高校由干学科设置多，涉及剧毒品使用的实验室也多，每年需要使用种类繁多的剧毒物品。
          </p>
        </div>
        <div class="collapseBox">
          <el-collapse
            class="collapse"
            @change="openCollapse_4 = !openCollapse_4"
          >
            <el-collapse-item>
              <template slot="title">
                <div class="collapseTitle">
                  <h2>具体说明</h2>
                  <div>
                    <span>{{ openCollapse_4 ? "收起" : "展开" }}</span>
                    <img
                      v-if="!openCollapse_4"
                      src="../../assets/service_support/展开符_1.png"
                      alt=""
                    />
                    <img
                      v-if="openCollapse_4"
                      src="../../assets/service_support/展开符_2.png"
                      alt=""
                    />
                  </div>
                </div>
              </template>
              <pre class="collapseContent">
一、加强剧毒品管理的重要性
    实验室是学校开展人才培养和科学研究的必备场所，实验室的安全管理，尤其是剧毒品的安全管理对于整个高校的安全和稳定至关重要。许多高校由干学科设 置多，涉及剧毒品使用的实验室也多，每年需要使用种类繁多的剧毒物品。与此同时，高校实验室又具有使用频繁，人员集中且流动性大的特点。这些情况充分表明 了高校实验室安全状况的复杂性和加强实验室剧毒物品安全管理的重要性，如果在购买、储存、转运、使用等环节中管理不善，一旦出了问题，就会给国家和人民群 众造成不可估量的损失。所以，对于剧毒品的安全管理，从学校领导、职能机构到相关的师生员工都必须高度重视，严格按照相关规章制度办事，把安全工作抓紧抓 好。
二、剧毒品的采购管理
    高校剧毒品管理的重点是采购管理、使用管理。学校应成立由保卫处、实验室处(或设备处)或采购中心等职能部门牵头的管理机构，统一领导，分工协作。 保卫处负责学校申购剧毒品的审批、储存和使用全过程的监管；实验室处(或设备处)或采购中心负责制定剧毒品的管理办法、剧毒品使用人的培训计划，以及实验 室对剧毒品的领用、保管和使用过程的监管；各实验室应有专门人员负责本实验室剧毒品的申购、使用与保管。储存剧毒药品的实验室必须配备保险柜。要形成严密 的校内剧毒品管理网，以确保安全。剧毒品的购买规定如下：
    （一）由采购人(必须是教职工)根据实验室需要认真填。写《剧毒品请购审批单》，双人签字。一式两份。由所在学院(系)签字盖章，再报保卫处审核，最后由校采购中心或物资供应部门统一向当地公安局申请，持准购证到公安机关指定的销售单位购买。
    （二）领用剧毒品应由两人(其中一人必须是教职工)共同持《剧毒品申领审批单》去供应部门库房领取，单独一人不能领取。
    （三）剧毒品的领用必须按照“谁领用谁负责”的原则进行管理。使用剧毒品的实验室要指定两个专门人员(其中一人必须是教职工)对剧毒品使用的全过程负责。
    （四）未经保卫处审核、公安机关批准，任何单位和个人都不准通过私人等关系非法购买或接受、赠送刷毒品。持有、使用剧毒物品的单位和个人，均不得将剧毒品私自出售、转让或赠送他人。
三、剧毒品的储存管理
    （一）剧毒品的储存和保管不能开架存放，均应保存在保险柜内，并应有明显的“剧毒”标志。性质相抵触的剧毒物品不能同柜存放，具有腐蚀性的或需要低 温保存的剧毒品需单独存放，严禁在楼道里放置剧毒品和剧毒品柜。对于性质不稳定，容易分解变质、散发毒气的剧毒品，要经常检查，发现问题要及时处理。
    （二）必须坚持双人保管，双人收发，双人使用，双人运输，双人双锁的“五双”制度，使用人员和保管人员应具有相应的安全知识和技能，经过培训合格后才可上岗。
    （三）对剧毒品的品种、数量要进行核查、登记，做到账目清楚，账物相符。
    （四）设置专、兼职人员，严格执行剧毒品的保管、收发、领取等管理制度。
    （五）各实验室根据需要，精确计算用量，尽可能做到当天领用当天用完。
    （六）剧毒物品购买后应及时入库，不得暂存他处，并且必须经过称量、登记后方可入库。
    （七）剧毒品的瓶签要有鲜明、醒目的骷髅架的标签，以防混淆。
四、剧毒品的使用管理
    （一）各学院、各单位的第一把手是剧毒品安全管理的第一负责人。各实验室主任是该室剧毒品安全负责人，对本室安全保管、使用剧毒品负责。
    （二）剧毒品须由经过相关业务培训的人员使用，使用人员要了解所接触剧毒品的性质、特点和安全防护方法。
    （三）使用单位应具有可靠的安全设施、防护设备及防护用具。
    （四）使用单位须结合自身的具体情况，建立健全安全操作规程和各种安全规章制度，剧毒品消耗必须严格记录，做到账物相符、有毒品在水中的溶解度越大，其危险性越大。
    （五）要妥善保管防护用具和盛装研磨、搅拌剧毒物品的专用工具，不得挪作他用，不得乱扔乱放。
    （六）建立危险品库(保险柜)，严格领取、清退制度。剧毒物品要经批准后随用随领，领取数量不得超过当天使用量，剩余的要及时退回给保管人员，禁止开架存放，严禁使用场所私设“小仓库”。
    （七）不得自行处理和排放剧毒物品的废渣、废液、废包装等。须妥善保管，最后要通过公安机关到指定的单位对其进行处理。
    （八）与外单位协作项目使用剧毒物品的，应向保卫处申报备案并接受检查。
五、剧毒气体的使用规定
    （一）使用场所要配备防毒面具和其他防护用具。
    （二）使用中禁止敲击、碰撞气瓶。
    （三）瓶阀被冻结时，不得用火烘烤，应该用热水浇开；开瓶时，人要站在出气口侧面。
    （四）气瓶不要靠近热源，夏季要防止日光曝晒。
    （五）气瓶不得用电磁起重机搬运。
    （六）瓶内气体不得用尽，必须留有剩余压力。
    （七）盛装易聚合气体的气瓶，不得放置于有放射线的场所
              </pre>
            </el-collapse-item>
          </el-collapse>
          <div class="decorate"></div>
        </div>
      </div>

      <div class="tabItem">
        <div class="content">
          <img src="../../assets/service_support/示例图_1.png" alt="" />
          <h2>毒药品对人体的作用</h2>
          <span>2021-08-28</span>
          <p>
            化学药品大致分为二类，一类是具有刺激性腐蚀性药物，一类是有毒化学药品。
          </p>
        </div>
        <div class="collapseBox">
          <el-collapse
            class="collapse"
            @change="openCollapse_5 = !openCollapse_5"
          >
            <el-collapse-item>
              <template slot="title">
                <div class="collapseTitle">
                  <h2>具体说明</h2>
                  <div>
                    <span>{{ openCollapse_5 ? "收起" : "展开" }}</span>
                    <img
                      v-if="!openCollapse_5"
                      src="../../assets/service_support/展开符_1.png"
                      alt=""
                    />
                    <img
                      v-if="openCollapse_5"
                      src="../../assets/service_support/展开符_2.png"
                      alt=""
                    />
                  </div>
                </div>
              </template>
              <!-- <p
                class="collapseContent"
                v-html="
                  `Q:如何进行重量管理? <br/> A:重量管理通过智能化学管理终端进行，终端带重登记功能，化学品管理环节中与重量相关的环节(入库,用量记录,报废) , 均在该终端进行。<br/> Q:自动登记重量的精度是多少? <br/> A:智能化学管理终端的称重精度为0.1g,满足大部分管制类危化品的登记需求;若使用量小于0.1g,可以使用手动登记用量。<br/> Q:手动登记用量和自动登记有什么区别? <br/> A:手动登记需要在电脑端或微信端进行操作，自动登记只需在智能化学管理终端感应即可。手动和自动登记都会改变化学品的剩余剩余重量。<br/> Q:自动感应的重量与实际情况不符怎么办? <br/> A:可以通过手动登记进行修正,修正后的数据作为注释存在，并保留登记人的信息;可以多次修改。`
                "
              ></p> -->
              <pre class="collapseContent">
1、毒物
    某些侵入人体的少量物质引起局部刺激或整个机体功能障碍的任何疾病都称为中毒，这类物质称为毒物。根据毒物侵入的途径，中毒分为摄入中毒、呼吸中毒和接触中毒。接触中毒和腐蚀性中毒有一定区别，接触中毒是通过皮肤进入皮下组织，不一定立即引起表面的灼伤，腐蚀性中毒是使接触它的那一部分组织立即受到伤害。毒物的剂量与效应之间的关系称为毒物的毒性，习惯上用半致死剂量（LD50）或半致死浓度（LC50）作为衡量急性毒性大小的指标，将毒物的毒性分 为剧毒、高毒、中等毒、低毒、微毒五级。
    上述分级未考虑其慢性毒性及致癌作用，我国国家标准GB 5044-85《职业性接触毒物危害程度分级》根据毒物的LD50值、急慢性中毒的状况与后果、致癌性、工作场所最高允许浓度等6项指标全面权衡，将毒物 的危害程度分为1-Ⅳ级。国家（标准(GB2893-82)中规定的四种安全色是：红、蓝、黄、绿。
2、刺激性腐蚀性药物
    这类药物有的有刺激性，对眼睛、粘膜、气管有刺激作用，腐蚀损害皮肤、组织，对眼睛非常危险。轻微引起喉痛、粘膜红肿（有的催泪）；重者引起气管炎、肺气肿，甚至死亡。这类药物包括：
    ①酸类、酸酐及与潮气产生酸的物质。例如硫酸、氟氢酸、硝酸、盐酸、五氧化二磷、醋酸、醋酸酐、酰氯化合物等。兹将强腐蚀性药物介绍如下：
    （a）硫酸 是强腐蚀性酸类，吸水力强，使组织碳化，与水反应生强热；与过氯酸、高锰酸钾反应，能爆炸！
    （b）氟氢酸 强腐蚀性酸，伤口异常疼痛，可使体弱伤者疼至休克。
    （c）硝酸及氧化氮 强刺激性、腐蚀性，与皮肤或组织中蛋白质作用使之变黄。
    （d）氮氧化物主要伤害人体的呼吸道深部的细支气管、肺泡。
    ②碱类：如氢氧化钠、氢氧化钾、氨水（氨气）、有机胺类及水解生成氨之化合物。前三者对眼睛特别危险。
    ③卤素及有机ω-卤代物。除具有强刺激性、腐蚀性外，还有催泪性并且是强氧化剂。
3、有毒化学药品
    我们指那些吸入微量即能致死的化学药品是剧毒药品，水银及汞盐、氰化物（氰氢酸、氰化钾……等）、硫化氢、砷化物、一氧化碳、马钱子碱……等等都是剧毒药品。其他毒物也是很危险的。有毒药品对人体的作用，使组织器官受伤；现将他们的毒性列於下面：
    ①使窒息：如一氧化碳与红血球结合而中毒，氰化物与血液结合而使中毒，硫化氢使呼吸器官麻庳而中毒。硫化氢的毒性不比氰化氢低，但它有味，使人警觉，立即采取措施，或离开，如吸多了，就不觉臭味，反而带甜味，十分危险。
    ②扰乱人体内部生理、损坏器官，这类毒药引起系统性中毒，而且每种毒物有其损害的对象。如苯深入骨髓，损害造血器官，结果引起患者全身无力、贫血、白血球低等等；卤代烷使肝肾及神经受损害，钡盐损害骨骼，汞盐损害大脑中枢神经等等。
    ③麻醉作用：乙醚、氯仿等。
    ④过敏性药物：引起某些人的过敏反应，最常见的是接触性皮炎。
    ⑤致癌性药物。我们已经知道很多药物是致癌性的。像铅、汞、铍、镉……等长期接触能导致癌症。
    经口的 Hg、As、Pb 等急性中毒会引起牙龈出血、牙齿松动、恶心、呕吐、腹痛、腹泻等症状;铬化合物中 Cr＋6毒性最大，有强刺激性，引起蛋白变性，干扰酶系统;Hg 中毒严重时会导致震颤、动作困难、肢体抖动,失眠、多梦、抑郁、胸闷、心悸、多汗、恶心、牙龈出血；铅中毒病情加重症状为腹部阵发性绞痛、肌无力、肢端麻 木、贫血；氰化钾、氰化钠、丙烯腈等是剧毒品，进入人体50毫克即可致死，与皮肤接触经伤口进入人体，即可引起严重中毒。
              </pre>
            </el-collapse-item>
          </el-collapse>
          <div class="decorate"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openCollapse_1: false,
      openCollapse_2: false,
      openCollapse_3: false,
      openCollapse_4: false,
      openCollapse_5: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.erviceSupport {
  width: 100%;
  position: relative;
  .background {
    width: 100%;
    height: 420px;
    background: url("../../assets/service_support/背景.png") 0 0/100% 100%
      no-repeat;
    position: relative;
    h2 {
      width: 100%;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 60px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 168px;
      left: 0;
    }
  }
  .summary {
    width: 1200px;
    height: 83px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 120, 255, 0.41);
    opacity: 1;
    position: absolute;
    top: 374px;
    left: 360px;
    z-index: 9;
    .icon {
      width: 41px;
      height: 36px;
      position: absolute;
      top: 24px;
      left: 20px;
    }
    h2 {
      height: 83px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 83px;
      position: absolute;
      top: 0;
      left: 73px;
    }
    span {
      height: 83px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bolder;
      color: #000000;
      line-height: 83px;
      position: absolute;
      top: 0;
      right: 21px;
    }
  }
  .tabBox {
    width: 1200px;
    // height: 1101px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(99, 99, 99, 0.06);
    margin: 0 auto;
    margin-top: 78px;
    margin-bottom: 78px;
    .tabItem {
      width: calc(100% - 4px);
      border: 2px solid #ffffff;
      // height: 265px;
      position: relative;
      &:hover {
        border: 2px solid #0078ff;
        box-shadow: 0px 0px 20px 0px rgba(0, 120, 255, 0.29);
      }
      .content {
        width: 100%;
        height: 206px;
        img {
          width: 260px;
          height: 178px;
          position: absolute;
          top: 28px;
          left: 21px;
        }
        h2 {
          height: 24px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #0078ff;
          line-height: 24px;
          position: absolute;
          top: 49px;
          left: 312px;
        }
        span {
          height: 16px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          line-height: 16px;
          position: absolute;
          top: 96px;
          left: 312px;
        }
        p {
          width: 848px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          line-height: 30px;
          position: absolute;
          top: 140px;
          left: 312px;
        }
      }
      .collapseBox {
        width: 1155px;
        margin: 0 auto;
        ::v-deep .collapse {
          border-top: none;
          .el-collapse-item__header {
            height: 70px;
            border-bottom: none;
          }
          .el-icon-arrow-right {
            display: none;
          }
          .el-collapse-item__wrap {
            border-bottom: none;
          }
          .collapseTitle {
            width: 100%;
            position: relative;
            h2 {
              height: 70px;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #000000;
              line-height: 70px;
            }
            div {
              width: 80px;
              height: 38px;
              background: #e3e9f0;
              border-radius: 19px;
              position: absolute;
              top: 16px;
              right: 0;
              span {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                position: absolute;
                line-height: 38px;
                top: 0;
                left: 14px;
              }
              img {
                width: 16px;
                height: 9px;
                position: absolute;
                top: 15px;
                right: 9px;
              }
            }
          }
          .collapseContent {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
      }
      .decorate {
        width: 1155px;
        height: 1px;
        background: #e5e5e5;
      }
    }
  }
}
</style>