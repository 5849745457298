var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productCenterDetails"},[_vm._m(0),_c('div',{staticClass:"breadcrumb"},[_c('img',{attrs:{"src":require("../../assets/product_center_details/产品中心home_icon.png"),"alt":""}}),_c('span',{on:{"click":function($event){return _vm.tableSwitch(1)}}},[_vm._v("首页")]),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp;>&nbsp;')}}),_c('span',{on:{"click":function($event){return _vm.tableSwitch(2)}}},[_vm._v("产品中心")]),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp;>&nbsp;')}}),_c('span',[_vm._v("化学品重量数据传输模块")])]),_c('div',{staticClass:"contentBox"},[_c('h2',[_vm._v("化学品重量数据传输模块")]),_c('img',{attrs:{"src":require("../../assets/product_center_details/标题装饰.png"),"alt":""}}),_c('div'),_c('img',{attrs:{"src":require("../../assets/product_center_details/示例图_5.png"),"alt":""}}),_c('el-row',{staticClass:"mb-15"},[_c('el-col',{staticClass:"flex jc-sb"},_vm._l((_vm.imgArr),function(v,k){return _c('el-image',{key:'imgArr' + k,staticClass:"titleImg",staticStyle:{"width":"23%"},attrs:{"src":v,"preview-src-list":_vm.imgArr}})}),1)],1),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('型号：JELON D-01')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;数据传输终端通过高性能嵌入式软件自动控制，自动维护无线连接，自动数据采集，可以实现真正的无人值守，实现远程对设备数据采集，遥测，遥控和告警，运用于危险化学品入库、出库及使用过程中重量的实时传输。支持透明传输 /HTTP 协议/MQTT 私有云/阿里云/百度云/中移 Onenet/短信，可以简单快速的连接多家物联网云平台及现有平台，稳定简单易用。'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('基础功能及特点：')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;1、功耗低，内置低功耗高性能的嵌入式处理器；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;2、 多重软硬件可靠设计，复合式看门狗技术，使设备安全运行；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&nbsp;&nbsp;3、防浪涌设计,防反接保护；')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;4、支持 RS232+RS485/TTL 硬件串口接口；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;5、支持 GNSS（北斗+GPS）定位（选配）；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;6、支持多种工作模式，在线模式和调试模式；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;7、支持多路中心地址，4G 可同时连接 4 路中心服务器,2G 可以同时连接 2 路；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;8、 内嵌 TCP/UDP/DNS/PPP/HTTP/MQTT 等协议栈；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;9、支持连接阿里/中移/百度/私有 MQTT 云等物联平台；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&nbsp;&nbsp;10、优化网络协议栈，快速连接服务器；')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;11、 极速响应，连续快速稳定传输，不掉线；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;12、超大缓存，可连续大数据传输，不丢包；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;13、支持域名和 IP 两种连接服务器方式；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&nbsp;&nbsp;14、支持心跳包功能，保证可靠连接；')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;15、支持注册包功能，连接上服务器则首先发送指定数据包，方便服务器对终端的识别；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;16、支持 AT 命令、短消息命令、服务器下发命令三种方式对 DTU 进行参数配置和查询；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;17、支持多组采集指令自主配置和周期轮询；'
      )}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&nbsp;&nbsp;18、支持配置参数掉电保存；')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&nbsp;&nbsp;19、支持程序远程升级；')}}),_c('p',{staticClass:"introduce",domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&nbsp;&nbsp;20、网络状态灯指示；')}}),_c('p',{staticClass:"introduce",staticStyle:{"margin-bottom":"60px"},domProps:{"innerHTML":_vm._s(
        '&nbsp;&nbsp;&nbsp;&nbsp;21、工业级标准设计，工作温度-40-+85度；'
      )}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('h2',[_vm._v("产品中心")])])}]

export { render, staticRenderFns }